import { alpha } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    },
    grow: {
      flexGrow: 1
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  paper: {
    padding: 2
  },
  root: {
    padding: theme.spacing(3, 2)
  },
  bigAvatar: {
    margin: 10,
    width: 300,
    height: 300
  },
  noLabelselectmargin: {
    marginTop: "15px",
    marginLeft: "5px"
  },
  rating1: {
    width: 300,
    display: "flex",
    alignItems: "center"
  },
  container: {
    paddingTop: theme.spacing(6)
  },
  circleBox: {
    width: 75,
    height: 75,
    color: "#ffffff",
    backgroundColor: "#000000",
    borderRadius: "50%",
    margin: "0 10px",
    display: "inline-flex",
    border: "1px solid #d5d5d6",
    justifyContent: "center",
    alignItems: "center"
  },
  topHeader: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "6px",
    margin: "-16px -8px 8px -8px",
    fontSize: "14px",
    font: "Arial",
    [theme.breakpoints.up("sm")]: {
      margin: "-16px 0px 15px -0px",
      padding: "6px 15px"
    }
  },
  btnHidden: {
    display: "none"
  },
  buttonProgress: {
    position: "absolute",
    top: "30%",
    marginTop: -1,
    marginLeft: -1,
  },
}));
