import React, {
  useState,
  useEffect,
  useContext,
  Suspense,
  lazy,
  useRef,
} from "react";
import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import { StoreContext } from "./context/StoreContext";
import { Router, Route, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./App.css";
import { Helmet } from "react-helmet";
import {
  publicsiteByCompany,
  companyBySubdomain,
  companyLocationByCompany,
} from "./graphql/queries";
import {
  servicesOfCompany,
  skillByCompany,
} from "./queries/ListBookingsQueries";
import { parse } from "query-string";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import Logout from "./auth/Logout";
import { TipPage } from "./components/tip/TipPage";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Typography } from "@mui/material";
import mbLogo from "../src/images/MBLogo.svg";
import "typeface-roboto";
import "typeface-raleway";
import { buildMainTheme } from "./styles/PublicSiteStyles";
import Forgot from "./auth/Forgot";
import { fetchGTMIds, initializeGTM } from "./modules/GoogleTagManager";
import { checkCompanyHasTosFile } from "./utils/Common/Template";
import MaintenanceDialog from "./components/MaintenanceDialog";
Sentry.init({
  dsn: "https://058cfe0ab69349feb230ca4b204f17e4@o435799.ingest.sentry.io/5428728",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        labelComments:
          "What happened? Please tell us the steps that caused the issue.",
        subtitle2:
          "Please tell us the steps that you took that caused the issue. This will help us fix the problem. Thank you.",
      });
    }
    return event;
  },
  environment: process.env.REACT_APP_SENTRY_ENVT,
  integrations: [new Integrations.BrowserTracing()],
  release: "marketbox-public-site@" + process.env.npm_package_version,
  tracesSampleRate: 1.0, // lower this in prod between 0 to 1
});

const SelectService = lazy(() =>
  import("./components/booking/SelectService").catch((e) =>
    window.location.reload()
  )
);
const ProviderDirectory = lazy(() =>
  import("./components/booking/ProviderDirectory").catch((e) =>
    window.location.reload()
  )
);
const Provider = lazy(() =>
  import("./components/booking/Provider").catch((e) => window.location.reload())
);

const ChooseTimeslot = lazy(() =>
  import("./components/booking/ChooseTimeslot").catch((e) =>
    window.location.reload()
  )
);
const AutoSelectProviderTimeSlot = lazy(() =>
  import("./components/booking/AutoSelectProviderTimeSlot").catch((e) =>
    window.location.reload()
  )
);
const ConfirmAppointmentDetail = lazy(() =>
  import("./components/booking/ConfirmAppointmentDetails").catch((e) =>
    window.location.reload()
  )
);
const OrderPayment = lazy(() =>
  import("./components/booking/OrderPayment").catch((e) =>
    window.location.reload()
  )
);
const ConfirmOrder = lazy(() =>
  import("./components/booking/ConfirmOrder").catch((e) =>
    window.location.reload()
  )
);
const ChoosePackage = lazy(() =>
  import("./components/booking/ChoosePackage").catch((e) =>
    window.location.reload()
  )
);
const BookingComplete = lazy(() =>
  import("./components/booking/BookingComplete").catch((e) =>
    window.location.reload()
  )
);
const NotFound = lazy(() =>
  import("./components/NotFound").catch((e) => window.location.reload())
);
const PageNotFound = lazy(() =>
  import("./components/PageNotFound").catch((e) => window.location.reload())
);
const MsgTest = lazy(() =>
  import("./components/booking/MsgTest").catch((e) => window.location.reload())
);
const ContactPage = lazy(() =>
  import("./components/booking/ContactPage").catch((e) =>
    window.location.reload()
  )
);
const logger = new Logger("App");
let params;
const limit = process.env.REACT_APP_LISTLIMIT; // global setting for list limits

// set default theme
const defaultButtonColor = "#ff4400";
const defaultButtonTextColor = "white";
let buttonColor = localStorage.getItem("buttonColor") || defaultButtonColor;
let buttonTextColor =
  localStorage.getItem("buttonTextColor") || defaultButtonTextColor;

let theme = createTheme({
  palette: {
    primary: {
      main: buttonColor,
    },
  },
});

export const history = createBrowserHistory();
export const UserContext = React.createContext();

function App(props) {
  const [user, setUser] = useState();
  const [guestUser, setGuestUser] = useState();
  const [guestCheckout, setGuestCheckout] = useState(false);
  const [userAttributes, setAttributes] = useState({});
  const [companyId, setCompanyId] = useState("");
  const [company, setCompany] = useState();
  const [serviceTypes, setServiceTypes] = useState();
  const [companyLocations, setCompanyLocations] = useState();
  const [skills, setSkills] = useState();
  const [publicSiteSettings, setPublicSiteSettings] = useState();
  const { state, actions } = useContext(StoreContext);
  const [serviceLoading, setServiceLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [landingPage, setLandingPage] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const currentPath = "/";
  const [showLoader, setShowLoader] = useState(true);
  const [selectedColor, setSelectedColor] = useState(null);
  const [provAgnoFlow, setProvAgnoFlow] = useState(false);
  let pathingInfo = {
    currentPathname: null,
    currentSearch: null,
    message: "Please use the Previous and Next buttons to navigate.",
    openMessage: false,
  };

  // return new theme, set primary color only for now
  const fetchSkillsData = async (compId) => {
    const response = await API.graphql(
      graphqlOperation(skillByCompany, {
        companyId: compId,
        filter: {
          and: [{ active: { ne: false } }, { deleted: { ne: true } }],
        },
        limit,
      })
    );
    const result = await response.data.skillByCompany.items;
    setSkills(result);
  };

  const fetchServiceTypeData = async (compId) => {
    const filter = {
      and: [
        { active: { ne: false } },
        { deleted: { ne: true } },
        { isVisible: { ne: false } },
      ],
    };
    const response = await API.graphql(
      graphqlOperation(servicesOfCompany, {
        companyId: compId,
        filter,
        limit,
      })
    );
    const result = await response.data.serviceTypeByCompany.items;
    setServiceTypes(sortByOrdinal(result));
    setServiceLoading(false);
  };

  const fetchCompanyLocations = async (companyId, offersRemoteServices) => {
    const filter = {
      and: [{ active: { ne: false } }, { deleted: { ne: true } }],
    };

    const response = await API.graphql(
      graphqlOperation(companyLocationByCompany, {
        companyId,
        filter,
        limit,
      })
    );
    let result = await response.data.companyLocationByCompany.items;
    // add "My Address to the beginning of the array"
    if (offersRemoteServices) {
      result.unshift({
        id: "0",
        addressoneline: "Your address",
        locationname: "My Location",
      });
    }
    setCompanyLocations(result);
  };

  const fetchCompanyPublicSettings = async (compId) => {
    let primaryColor = "";
    const response = await API.graphql(
      graphqlOperation(publicsiteByCompany, { companyId: compId })
    );
    let [settings] = response.data.publicsiteByCompany.items;
    let styleCustomization;
    if (
      settings?.StyleCustomization &&
      settings.StyleCustomization.length > 0
    ) {
      styleCustomization = JSON.parse(settings.StyleCustomization);
      setPublicSiteSettings({
        ...settings,
        StyleCustomization: styleCustomization,
      });
    } else {
      styleCustomization = {
        bookingFlowCustomizations: {
          showHeader: true,
          backgroundColor: publicSiteSettings.buttonColor,
          opacity: 0.2,
          circularLogo: false,
          showHeadings: true,
          showLogo: true,
          fontTypeForAllHeading: "Raleway",
          mainHeading: company?.name
            ? company.name
            : "Please set Heading 1 in company public settings",
          mandatoryNotes: false,
          checkoutOption: "ACCOUNT_OR_GUEST",
        },
      };
      setPublicSiteSettings({
        ...settings,
        StyleCustomization: styleCustomization,
      });
    }

    localStorage.setItem("backgroundImgUrl", settings.backgroundImageUrl);
    localStorage.setItem("buttonColor", settings.buttonColor);
    localStorage.setItem("heading1Text", settings.heading1Text);
    localStorage.setItem("heading2Text", settings.heading2Text);
    localStorage.setItem("textColor", settings.textColor);
    localStorage.setItem(
      "buttonTextColor",
      styleCustomization?.bookingFlowCustomizations?.buttonTextColor ||
        defaultButtonTextColor
    );

    if (
      styleCustomization?.tipPageCustomizations?.showTipOption !== undefined
    ) {
      localStorage.setItem(
        "showTipOption",
        styleCustomization?.tipPageCustomizations.showTipOption
      );
    }
    theme = buildMainTheme(
      settings.buttonColor.trim(),
      buttonTextColor,
      settings?.textColor?.trim()
    );
    setSelectedColor(settings.buttonColor.trim());
    primaryColor = settings.buttonColor;
    primaryColor = primaryColor.trim(); // trim in case space added.
    setSelectedColor(primaryColor);
  };

  useEffect(() => {
    // get companyId passed in query string
    if (props && props.location && props.location.search) {
      params = parse(props.location.search);
      logger.debug("page = " + params.p); // "tippage"
      if (params.p && params.p !== "") {
        setLandingPage(true);
      }
    }

    async function doFetch() {
      await init();
    }
    doFetch();
  }, [selectedColor]);

  useEffect(() => {
    async function doFetch() {
      setLoggedOut(false);
      await init();
      history.push("/");
      window.location.reload();
    }
    if (loggedOut) doFetch();
  }, [loggedOut]);

  function hideLoader() {
    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();
      setShowLoader(!showLoader);
    }
  }

  const historyListening = useRef(false);
  if (!historyListening.current) {
    historyListening.current = true;
    history.listen((newLocation, action) => {
      try {
        if (backPathingEnabled(newLocation)) {
          //In the case the backpathing should be enabled, return and do nothing.
          return;
        }

        if (action === "PUSH") {
          if (
            newLocation.pathname !== pathingInfo.currentPathname ||
            newLocation.search !== pathingInfo.currentSearch
          ) {
            pathingInfo.openMessage = false;
            state.bookingState.seenConfirmOrder = false;
            state.bookingState.seenOrderPayment = false;
            state.bookingState.seenBookingComplete = false;
            // Save new location
            pathingInfo.currentPathname = newLocation.pathname;
            pathingInfo.currentSearch = newLocation.search;
            // Clone location object and push it to history
            history.push({
              pathname: newLocation.pathname,
              search: newLocation.search,
            });
          }
        } else {
          // Send user back if they try to navigate back
          pathingInfo.openMessage = true;
          history.go(1);
        }
      } catch (e) {
        console.log("history listen error", e);
      }
    });
  }

  function backPathingEnabled(path) {
    if (
      path === "/" ||
      path === "/logout" ||
      path === "/login" ||
      path === "/notfound" ||
      path === "/msgtest" ||
      path === "/service"
    ) {
      return true;
    } else {
      return false;
    }
  }

  async function init() {
    setLoading(false);
    state.bookingState = {};
    // log in as guest if not authenticated
    await SignInAsGuest();
    const { compId, company } = await getCompanyFromDomain();
    const { offersRemoteServices } = company;
    if ((company && company.active === false) || !company) {
      history.push("/notfound");
      setLoading(true);
      hideLoader();
      return;
    }

    //Append MarketBox GTMId
    initializeGTM(process.env.REACT_APP_MB_GTM_ID);

    //route user to maintenance dialog if maintenance flag is ON
    if (process.env.REACT_APP_FF_ONGOING_MAINTENANCE === "true") {
      history.push("/maintenance");
    }

    const [, , , , gtmIds] = await Promise.all([
      fetchServiceTypeData(compId),
      fetchSkillsData(compId),
      fetchCompanyLocations(compId, offersRemoteServices),
      fetchCompanyPublicSettings(compId),
      fetchGTMIds(compId),
    ]);
    console.log("gtmIds", gtmIds);

    if (gtmIds && Array.isArray(gtmIds) && gtmIds.length > 0) {
      initializeGTM(gtmIds[0]); //for now we will only embed one ID
    }

    setCompanyId(compId);
    state.bookingState.company = company;
    state.bookingState.companyId = compId;

    //check if company has a Tos and set 'hasTos' in bookingState
    const hasTos = await checkCompanyHasTosFile(compId);
    actions.setBookingState({
      ...state.bookingState,
      hasTos: hasTos,
    });

    setLoading(true);
    hideLoader();
  }

  function sortByOrdinal(services) {
    if (services) {
      return services.sort((s1, s2) => {
        if (s1.category.ordinal > s2.category.ordinal) return 1;
        if (s1.category.ordinal < s2.category.ordinal) return -1;
        if (s1.category.id === s2.category.id) {
          if (s1.ordinal > s2.ordinal) return 1;
          if (s1.ordinal < s2.ordinal) return -1;
          return 0;
        }
        return 0;
      });
    }
    return [];
  }

  async function SignInAsGuest() {
    let userAuthenticated = false;
    let user = null;
    try {
      // check if user signed in
      await Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user) => {
          if (
            user &&
            user.signInUserSession &&
            user.signInUserSession.idToken &&
            user.signInUserSession.idToken.payload
          ) {
            let groups =
              user.signInUserSession.idToken.payload["cognito:groups"];
            if (groups && groups.length) {
              setUser(user);
              setAttributes(user.attributes);
              if (
                user.attributes.email === process.env.REACT_APP_MB_GUEST_EMAIL
              )
                setGuestUser(true);
              else setGuestUser(false);
              userAuthenticated = true;
              state.bookingState.user = user;
            }
          }
        })
        .catch((err) => logger.debug("*** Error: User is not authenticated"));

      if (!userAuthenticated) {
        const user = await Auth.signIn(
          process.env.REACT_APP_MB_GUEST_EMAIL,
          process.env.REACT_APP_MB_GUEST_PWD
        );
        setUser(user);
        setAttributes(user.attributes);
        setGuestUser(true);

        state.bookingState.user = user;
      } else {
        console.log("Found logged in user:", JSON.stringify(user));
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        logger.debug(
          "An error occurred attempting to login. Error was: " +
            JSON.stringify(err)
        );
      }
    }
  }

  const updateCurrentUser = (u) => {
    state.bookingState.user = u;
    state.bookingState.bookingUser = u;
    actions.setBookingState(state.bookingState);
    setUser(u);
    setGuestUser(false);
  };

  const setBookingUserInState = (guest_mbuser) => {
    state.bookingState.bookingUser = guest_mbuser;
    state.bookingState.guestCheckout = true;
    setGuestCheckout(true);
  };

  const signOutOp = async () => {
    setLoggedOut(true);
    history.push("/logout");
  };

  const setClientInfoInBookingState = (clientInfoObj) => {
    state.bookingState.isNewClient = clientInfoObj.isNewClient;
    state.bookingState.client = clientInfoObj.client;
    actions.setBookingState(state.bookingState);
  };

  const setClientPackagesInBookingState = (clientPackages) => {
    state.bookingState.clientExistingPackages = clientPackages;
    actions.setBookingState(state.bookingState);
  };

  const getCompanyFromDomain = async () => {
    // enter domain here
    //frasiercrane-dev.gomarketbox.com
    let domain = window.location.hostname;
    //"justkeepswimming.gomarketbox.com";
    //let domain = "frasiercrane-dev.gomarketbox.com"; //"theebigrelease.gomarketbox.com"; //frasiercrane-dev.gomarketbox.com";
    let compId = "";
    let company = {};
    // fix to work for localhost
    if (domain === "localhost") {
      //domain = "gaming-dev.gomarketbox.com";
      domain = "frasiercrane.gomarketbox.com";
      //domain = "programmersyounite.gomarketbox.com";
      // domain = "jccs-devm.gomarketbox.com";
      // domain = "april21.gomarketbox.com";
    }

    const response = await API.graphql(
      graphqlOperation(companyBySubdomain, {
        subdomain: domain,
      })
    );
    if (
      response.data.companyBySubdomain.items &&
      response.data.companyBySubdomain.items.length === 1
    ) {
      compId = response.data.companyBySubdomain.items[0].id;
      company = response.data.companyBySubdomain.items[0];
      setCompany(company);
      setCompanyId(compId);
      providerAgnosticFlow(company);
      localStorage.setItem("companyId", compId);
      localStorage.setItem("company", JSON.stringify(company));
    } else {
      logger.error(
        "*** ERROR ***: Unable to find a company for subdomain: " + domain
      );
    }
    return { compId, company };
  };

  function providerAgnosticFlow(comp) {
    if (comp.ProvAgnoFlowConfig) {
      const ProvAgnoFlowConfig = JSON.parse(comp.ProvAgnoFlowConfig);
      setProvAgnoFlow(ProvAgnoFlowConfig.isEnabled);
    }
  }

  function getCompanyTagline() {
    let tagline;
    if (company && company?.tagline?.length <= 0) {
      tagline = company.name;
    } else {
      tagline = company.tagline;
    }
    return tagline;
  }

  if (showLoader) {
    return null;
  }

  function FallbackComponent() {
    return (
      <div>
        We are sorry but an error has occurred. Don't worry, our development
        team has already been notified and will be denied dessert tonight.
      </div>
    );
  }

  const myFallbackComponent = <FallbackComponent />;

  return (
    <>
      <Sentry.ErrorBoundary fallback={myFallbackComponent} showDialog>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <UserContext.Provider
              value={{ user, userAttributes, guestUser, guestCheckout }}
            >
              <div id="main-content" style={{ minHeight: "90vh" }}>
                <Helmet>
                  <title>
                    {company && company.active !== false
                      ? getCompanyTagline()
                      : "Company Not Found"}
                  </title>
                </Helmet>
                <Router history={history}>
                  <Route
                    path="/maintenance"
                    component={() => {
                      return loading ? (
                        <div>
                          <Suspense fallback={<div></div>}>
                            {process.env.REACT_APP_FF_ONGOING_MAINTENANCE ===
                            "true" ? (
                              <MaintenanceDialog />
                            ) : (
                              <SelectService
                                provAgnoFlow={provAgnoFlow}
                                serviceTypes={serviceTypes}
                                skills={skills}
                                companyLocations={companyLocations}
                                companyId={companyId}
                                company={company}
                                loading={serviceLoading}
                                publicsitesettings={publicSiteSettings}
                                signOutOp={signOutOp}
                                pathingInfo={pathingInfo}
                                selectedColor={
                                  selectedColor ? selectedColor : "#7AA0CD"
                                }
                              />
                            )}
                          </Suspense>
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/service"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <SelectService
                            provAgnoFlow={provAgnoFlow}
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            pathingInfo={pathingInfo}
                            signOutOp={signOutOp}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/contact"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ContactPage
                            company={company}
                            publicsitesettings={publicSiteSettings}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/notfound"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <NotFound
                            company={company}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/pagenotfound"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <PageNotFound
                            company={company}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path={[
                      "/providers/:_country/:_stateprov/:_city",
                      "/providers/:_country/:_stateprov",
                      "/providers/:_country",
                      "/providers",
                    ]}
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ProviderDirectory
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/provider/:providerName"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <Provider
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/choose-timeslot"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ChooseTimeslot
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/auto-timeslot"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <AutoSelectProviderTimeSlot
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/confirmation"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ConfirmAppointmentDetail
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/payment"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <OrderPayment
                            companyId={companyId}
                            company={company}
                            publicsitesettings={publicSiteSettings}
                            authuser={user}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/confirm-order"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ConfirmOrder
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            signOutOp={signOutOp}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/choose-package"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <ChoosePackage
                            serviceTypes={serviceTypes}
                            skills={skills}
                            companyLocations={companyLocations}
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            publicsitesettings={publicSiteSettings}
                            pathingInfo={pathingInfo}
                            signOutOp={signOutOp}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/booking-complete"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <BookingComplete
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            signOutOp={signOutOp}
                            publicsitesettings={publicSiteSettings}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/logout"
                    component={() => {
                      return loading ? (
                        <Suspense fallback={<div></div>}>
                          <Logout
                            companyId={companyId}
                            company={company}
                            loading={serviceLoading}
                            setLoggedOut={setLoggedOut}
                            publicsitesettings={publicSiteSettings}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />{" "}
                        </Suspense>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/login"
                    component={() => {
                      return loading ? (
                        <div>
                          <SignIn
                            companyId={companyId}
                            company={company}
                            setUser={updateCurrentUser}
                            setClient={setClientInfoInBookingState}
                            setClientPackages={setClientPackagesInBookingState}
                            publicsitesettings={publicSiteSettings}
                            appPath={currentPath}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/signup"
                    component={() => {
                      return loading ? (
                        <div>
                          <SignUp
                            companyId={companyId}
                            company={company}
                            setBookingUserInState={setBookingUserInState}
                            setUser={updateCurrentUser}
                            setClient={setClientInfoInBookingState}
                            setClientPackages={setClientPackagesInBookingState}
                            publicsitesettings={publicSiteSettings}
                            appPath={currentPath}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/forgot"
                    component={() => {
                      return loading ? (
                        <div>
                          <Forgot
                            companyId={companyId}
                            company={company}
                            setUser={updateCurrentUser}
                            setClient={setClientInfoInBookingState}
                            setClientPackages={setClientPackagesInBookingState}
                            publicsitesettings={publicSiteSettings}
                            appPath={currentPath}
                            pathingInfo={pathingInfo}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    path="/msgtest"
                    component={() => {
                      return loading ? (
                        <div>
                          <Suspense fallback={<div></div>}>
                            <MsgTest
                              companyLocations={companyLocations}
                              companyId={companyId}
                              company={company}
                              loading={serviceLoading}
                              publicsitesettings={publicSiteSettings}
                              signOutOp={signOutOp}
                              pathingInfo={pathingInfo}
                              selectedColor={
                                selectedColor ? selectedColor : "#7AA0CD"
                              }
                            ></MsgTest>
                          </Suspense>
                        </div>
                      ) : (
                        ""
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/"
                    component={() => {
                      if (params && params.p && params.p === "tippage") {
                        return loading ? (
                          <TipPage
                            companyId={companyId}
                            company={company}
                            authuser={user}
                            selectedColor={
                              selectedColor ? selectedColor : "#7AA0CD"
                            }
                          />
                        ) : (
                          ""
                        );
                      } else {
                        return loading ? (
                          <Suspense fallback={<div></div>}>
                            <SelectService
                              provAgnoFlow={provAgnoFlow}
                              serviceTypes={serviceTypes}
                              skills={skills}
                              companyLocations={companyLocations}
                              companyId={companyId}
                              company={company}
                              loading={serviceLoading}
                              publicsitesettings={publicSiteSettings}
                              signOutOp={signOutOp}
                              pathingInfo={pathingInfo}
                              selectedColor={
                                selectedColor ? selectedColor : "#7AA0CD"
                              }
                            />
                          </Suspense>
                        ) : (
                          ""
                        );
                      }
                    }}
                  />
                </Router>
              </div>
              <div className="footer">
                <Typography variant="subtitle2">Powered by</Typography>
                <img
                  src={mbLogo}
                  alt="MarketBox"
                  style={{
                    width: "90px",
                    marginBottom: "10px",
                  }}
                />
              </div>
            </UserContext.Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default Sentry.withProfiler(withRouter(App));
