import { loadData } from "../Common/Template";
import { logSystemError } from "../../modules/SystemErrorService";
import { createTimeDisplayInfoForTimeZone } from "../../modules/TimeService";

export const ProviderBookingConfirmation = async ({
  provider,
  client,
  company,
  serviceType,
  bookings,
  orderNo,
  orderType,
  orderNotes,
  wdDisplay,
}) => {
  const adjustedBookings = bookings.map(
    ({ TimeDisplayInfo, minutes, ...rest }) => ({
      ...rest,
      TimeDisplayInfo: createTimeDisplayInfoForTimeZone(
        TimeDisplayInfo.dtstamp_str,
        provider.timezone,
        minutes
      ),
    })
  );
  try {
    let htmlTemplate = await loadData(
      `https://do1e1nd2rp5b.cloudfront.net/public/email-templates/${company.id}/order-receipt-provider-tokenized.html`,
      company.id
    );

    // check if we got a template, if not load a generic template
    if (htmlTemplate === "Error") {
      // load generic template
      htmlTemplate = await loadData(
        "https://do1e1nd2rp5b.cloudfront.net/public/email-templates/modified-templates/order-receipt-provider-tokenized.html",
        company.id
      );
    }
    // if still an error, we have a network problem
    if (htmlTemplate === "Error") {
      return "";
    }
    htmlTemplate = htmlTemplate.replace(
      /\[company-auth-site-url\]/g,
      process.env.REACT_APP_AUTH_SITE_URL
    );
    htmlTemplate = htmlTemplate.replace(
      /\[company-logo-url\]/g,
      company.logoUrl
    );
    htmlTemplate = htmlTemplate.replace(
      /\[company-color\]/g,
      company.primaryColor
    );
    // now substitute values into template
    //  company values
    htmlTemplate = htmlTemplate.replace(/\[company_name\]/g, company.name);
    htmlTemplate = htmlTemplate.replace(
      /\[company_email\]/g,
      company.emailaddress
    );
    htmlTemplate = htmlTemplate.replace(
      /\[provider_firstname\]/g,
      provider.firstname
    );
    htmlTemplate = htmlTemplate.replace(
      /\[provider_lastname\]/g,
      provider.lastname
    );
    const clientName =
      client.user.firstname + " " + client.user.lastname.charAt(0) + ".";
    htmlTemplate = htmlTemplate.replace(/\[client_name\]/g, clientName);
    htmlTemplate = htmlTemplate.replace(
      /\[client_phone\]/g,
      getClientPhone(client.user)
    );
    htmlTemplate = htmlTemplate.replace(
      /\[servicetype_name\]/g,
      serviceType.name
    );
    htmlTemplate = htmlTemplate.replace(/\[order_no\]/g, orderNo);
    //Check here for virtual
    htmlTemplate = htmlTemplate.replace(
      /\[booking_location\]/g,
      bookings[0].location
    );
    //Values past booking_location are repeatable and had to be updated below:

    //Old Values - Maintained for compatibility
    htmlTemplate = htmlTemplate.replace("[company-name]", company.name);
    htmlTemplate = htmlTemplate.replace(
      "[company-email]",
      company.emailaddress
    );
    // provider values
    htmlTemplate = htmlTemplate.replace(
      "[provider-first-name]",
      provider.firstname
    );
    htmlTemplate = htmlTemplate.replace(
      "[provider-last-name]",
      provider.lastname
    );
    // client values
    htmlTemplate = htmlTemplate.replace(
      /\[client-name\]/g,
      `${client.user.firstname ? client.user.firstname : ""} ${
        client.user.lastname ? client.user.lastname : ""
      }`
    );
    htmlTemplate = htmlTemplate.replace(
      "[client-phone]",
      getClientPhone(client.user)
    );
    //service type
    htmlTemplate = htmlTemplate.replace("[service-type]", serviceType.name);
    //order number
    htmlTemplate = htmlTemplate.replace("[order-no]", orderNo);

    //booking(s)
    htmlTemplate = htmlTemplate.replace(
      "[booking-location]",
      adjustedBookings[0].location
    );
    htmlTemplate = htmlTemplate.replace(
      "[booking_date]",
      adjustedBookings[0].TimeDisplayInfo.dt_long_disp
    );
    htmlTemplate = htmlTemplate.replace(
      "[booking_time]",
      adjustedBookings[0].TimeDisplayInfo.tm_st_disp
    );
    htmlTemplate = htmlTemplate.replace(
      "[end_time]",
      adjustedBookings[0].TimeDisplayInfo.tm_et_disp
    );
    htmlTemplate = htmlTemplate.replace(
      "[company-auth-site-url]",
      process.env.REACT_APP_AUTH_SITE_URL
    );
    let packageSection = "";
    // pacakge section if more than one booking
    if (adjustedBookings.length > 1) {
      packageSection = `  <tr>
                                  <td width="70%">
                                      <b>Additional bookings are confirmed for the following date(s) and time(s):</b>
                                  </td>
                              </tr>
                              <tr>
                                  <td width="70%">
                                      &nbsp; 
                                  </td>
                              </tr>`;
      for (let i = 1; i < adjustedBookings.length; i++) {
        let bookingRow = `   <tr>
                                  <td width="70%">
                                      <b>Date:</b> [booking_date]
                                  </td>
                              </tr>
                              <tr>
                                  <td width="70%">
                                      <b>Time:</b> [booking_time] to [end_time]
                                  </td>
                                  </tr>
                              <tr>
                                  <td width="70%">
                                      &nbsp; 
                                  </td>
                              </tr>`;
        bookingRow = bookingRow.replace(
          "[booking_date]",
          adjustedBookings[i].TimeDisplayInfo.dt_long_disp
        );
        bookingRow = bookingRow.replace(
          "[booking_time]",
          adjustedBookings[i].TimeDisplayInfo.tm_st_disp
        );
        bookingRow = bookingRow.replace(
          "[end_time]",
          adjustedBookings[i].TimeDisplayInfo.tm_et_disp
        );
        packageSection += bookingRow;
      }
    }

    if (orderType === "ONGOING" && wdDisplay) {
      packageSection += `  <tr>
      <td width="70%">
          This booking will repeat every <b>${wdDisplay}.</b>
      </td>
  </tr>
  <tr>
      <td width="70%">
          &nbsp; 
      </td>
  </tr>`;
    }
    htmlTemplate = htmlTemplate.replace(
      "[additional_bookings]",
      packageSection
    );
    htmlTemplate = htmlTemplate.replace(
      "[notes]",
      typeof orderNotes === "string" ? orderNotes : ""
    );
    return htmlTemplate;
  } catch (err) {
    await logSystemError(
      company.id,
      "ERROR",
      "Error populating provider order-booking receipt template",
      0,
      err
    );
  }
  return "";
};

function getClientPhone(user) {
  if (user.phonepref === "MOBILE") {
    if (user.mobilephone) return user.mobilephone;
    else {
      if (user.homephone) return user.homephone;
      if (user.workphone) return user.workphone;
    }
  }
  if (user.phonepref === "HOME") {
    if (user.homephone) return user.homephone;
    else {
      if (user.mobilephone) return user.mobilephone;
      if (user.workphone) return user.workphone;
    }
  }
  if (user.phonepref === "WORK") {
    if (user.workphone) return user.workphone;
    else {
      if (user.mobilephone) return user.mobilephone;
      if (user.homephone) return user.homephone;
    }
  }
  if (user.mobilephone) return user.mobilephone;
  if (user.homephone) return user.homephone;
  if (user.workphone) return user.workphone;

  return "";
}

function getBookingDateTime(bookingDateTime, serviceDuration) {
  const bdt = new Date(bookingDateTime);
  let dtinfo = {};
  dtinfo.date = bdt.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  dtinfo.startTime = bdt.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  bdt.setMinutes(bdt.getMinutes() + serviceDuration);
  dtinfo.endTime = bdt.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  return dtinfo;
}
