import API, { graphqlOperation } from "@aws-amplify/api";
import {
  clientPackageByUser,
  getRefData,
  getCompany,
  getClientPackage,
} from "../graphql/queries";
import {
  createClient,
  createBooking,
  createOrder,
  updateClientPackage,
  createBillingProgress,
  createClientPackage,
  createBookingRequest,
  createServiceType,
  createUISession,
} from "../graphql/mutations";
import { getCompanyLocation } from "../graphql/queries";
import {
  updateProviderSchedule,
  deleteProviderSchedule,
} from "../graphql/mutations";
import {
  bundledServicesByCompany,
  regionalPricingByCompanyServiceType,
  packageByCompany,
} from "../queries/ListBookingsQueries";
import { execWrite, execReadBySortkey } from "./DBService";
import moment from "moment";
import { getServiceTaxRate } from "./TaxService";
import { createTimeblock, getAWSDate } from "../modules/ScheduleService";
import { handleSendEmail } from "../user/UserCommon";
import { ProviderBookingConfirmation } from "../utils/Common/ProviderBookingConfirmation";
import { trackOrderCompleted } from "../modules/Tracking";
import { guestCheckoutEmail } from "../utils/Common/guestCheckoutEmail";
import { TriggerManager } from "../modules/TriggerManager";
import * as Sentry from "@sentry/react";
import { VARIABLE_TAX_RATE } from "../utils/Constants";

const formatterCurr = (curr = "USD", cost) => {
  try {
    //returns in format $10.00 or £10.21
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: curr,
    });
    return formatter.format(Number(cost));
  } catch (e) {
    // ** older Safari/Mac does not support narrowSymbol **
    // So,
    // locale is undfined, currencyDisplay is 'default' i.e. 'symbol'
    //  USD will be returned as US$10.00, CAD as CA$10.00
    let formatter = new Intl.NumberFormat(undefined, {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency: curr,
    });
    return formatter.format(Number(cost));
  }
};
const handleOrderAndBookingCreation = async (bookingState, prps) => {
  let usedPkgIdForCredit = null;
  if (bookingState.boughtpackage)
    usedPkgIdForCredit = bookingState.newclientpkg.id;
  else if (bookingState.clientpackage) {
    usedPkgIdForCredit = bookingState.clientpackage.id;
  }

  let input = {
    ...{
      heldPackageSlots: bookingState.heldPackageSlots,
      packageBookedSlots: bookingState.packageBookedSlots,
      bookedByAdmin: bookingState.bookingOnBehalf ? true : false,
      uiSessionId: bookingState.uiSessionId,
      bookingTz: bookingState.bookingTz,
      clientNotes: bookingState.clientNotes,
      bookingAddress: getBookingAddress(bookingState),
      currency: bookingState.currency,
      acknowledgementDateTime: bookingState.acknowledgementDateTime,
      location: bookingState.location,
      bookingType: bookingState.sdt,
      chargeBreakup: bookingState.cbu,
      orderSummary: bookingState.osd,
      isVirtual: bookingState.isVirtual,
      providerInfo: bookingState.provider,
      heldSlots: bookingState.heldSlots,
      //    taxJurisdiction,
      userid: bookingState.bookingUser.username,
      providerid: bookingState.provider.id,
      company: prps.company,
      servicetype: {
        id: bookingState.serviceType.id,
        desc: bookingState.serviceType.desc,
        name: bookingState.serviceType.name,
        price: bookingState.serviceType.price,
        minutes: bookingState.serviceType.minutes,
      },
      booking: {
        date: new Date(bookingState.selectedslot.date),
        time: bookingState.selectedslot.label,
        slot12: bookingState.selectedslot.slot12,
        dateInfo: bookingState.selectedslot.dateInfo,
      },
      usedPkgIdForCredit: usedPkgIdForCredit,
      ...(bookingState.repeatingAppointment && {
        hasrepeatingappt: bookingState.repeatingAppointment,
        repeatingapptinfo: {
          apptlist: bookingState.repeatingApptList,
        },
      }),
    },
    bookingState: bookingState,
  };

  let localBookingDetails = null;
  try {
    localBookingDetails = await createOrderAndBookings(input);
    if (
      localBookingDetails &&
      localBookingDetails.order &&
      localBookingDetails.order.id
    ) {
      try {
        if (bookingState.promoData)
          await API.post("promoapi", "/redemption", {
            body: {
              order: localBookingDetails.order,
              promoData: bookingState.promoData,
              bookingUser: {
                ...localBookingDetails.client.user,
                username: localBookingDetails.client?.user.id,
              },
              actionUser: { ...bookingState.user?.attributes },
            },
          });
      } catch (e) {
        console.log("calling redempation api failed", e);
      }
      await updateOrderStats(
        prps.company.id,
        localBookingDetails.order.total,
        bookingState.sdt
      );
    }
  } catch (err) {
    console.error("Confirm booking error => ", err);
  }
  return localBookingDetails;
};

async function updateOrderStats(companyId, orderTotal, bookingType) {
  if (bookingType !== "forever") {
    try {
      const company = await API.graphql(
        graphqlOperation(getCompany, {
          id: companyId,
        })
      );
      if (company && company.data && company.data.getCompany) {
        let dbinfo;
        if (!company.data.getCompany.DashboardInfo) {
          dbinfo = {
            orders_today: 1,
            orders_mtd: 1,
            orders_ytd: 1,
            sales_today: orderTotal,
            sales_mtd: orderTotal,
            sales_ytd: orderTotal,
          };
        } else {
          dbinfo = JSON.parse(company.data.getCompany.DashboardInfo);
          dbinfo.orders_today = dbinfo.orders_today
            ? dbinfo.orders_today + 1
            : 1;
          dbinfo.orders_mtd = dbinfo.orders_mtd ? dbinfo.orders_mtd + 1 : 1;
          dbinfo.orders_ytd = dbinfo.orders_ytd ? dbinfo.orders_ytd + 1 : 1;
          dbinfo.sales_today = dbinfo.sales_today
            ? dbinfo.sales_today + orderTotal
            : orderTotal;
          dbinfo.sales_mtd = dbinfo.sales_mtd
            ? dbinfo.sales_mtd + orderTotal
            : orderTotal;
          dbinfo.sales_ytd = dbinfo.sales_ytd
            ? dbinfo.sales_ytd + orderTotal
            : orderTotal;
        }

        const updateCompany = /* GraphQL */ `
          mutation UpdateCompany($input: UpdateCompanyInput!) {
            updateCompany(input: $input) {
              id
              name
              DashboardInfo
            }
          }
        `;
        const result = await execWrite({
          opname: "updateCompany",
          op: updateCompany,
          input: {
            id: companyId,
            DashboardInfo: JSON.stringify(dbinfo),
          },
        });
      }
    } catch (e) {
      console.log("error updating company stats");
    }
  }
}

const createOrderAndBookings = async ({
  heldPackageSlots,
  packageBookedSlots,
  bookedByAdmin,
  uiSessionId,
  clientNotes,
  bookingAddress,
  currency,
  acknowledgementDateTime,
  bookingType,
  chargeBreakup,
  userid,
  providerid,
  company,
  servicetype,
  booking,
  location,
  heldSlots,
  usedPkgIdForCredit,
  isVirtual,
  orderSummary,
  bookingTz,
  bookingState,
}) => {
  //Create client
  let client = await getClientIfExists({
    userid,
    companyid: company.id,
  });
  if (client && client.error) {
    return {
      error: client.error,
    };
  }
  if (!client) {
    const clientData = {
      userId: userid,
      currency: currency, //company.currency ? company.currency : "CAD"
      companyId: company.id,
      clientUserId: userid,
      clientCompanyId: company.id,
      accountbalance: 0.0,
    };
    client = await execWrite({
      opname: "createClient",
      op: createClient,
      input: clientData,
    });
    if (client && client.error) {
      return {
        error: client.error,
      };
    }
  }
  let orderType = "SINGLE";
  if (bookingType === "package") orderType = "PACKAGE";
  if (bookingType === "forever") orderType = "ONGOING";
  //Create Order
  const orderNo = await getNewOrderNo(company.id);
  const orderData = {
    bookedByAdmin,
    clientnotes: clientNotes,
    orderNo,
    bookingAddress: JSON.stringify(bookingAddress), //is JSON
    currency,
    desc: prepareOrderDesc(servicetype, booking, bookingType), //servicetype.name,
    type: orderType,
    companyId: company.id,
    providerId: providerid,
    orderProviderId: providerid,
    subtotal: chargeBreakup.subtotal,
    servicechargeamt: chargeBreakup.servicechargeamt,
    taxamt: chargeBreakup.taxamt,
    total: chargeBreakup.total,
    status: "CONFIRMED",
    orderCompanyId: company.id,
    taxrate: chargeBreakup.taxrate,
    clientId: client.id,
    legaltermsAcceptedAt: acknowledgementDateTime,
    orderSummary: JSON.stringify(orderSummary),
    orderClientId: client.id,
    ...(usedPkgIdForCredit && {
      orderClientpackageId: usedPkgIdForCredit,
    }),
  };

  const order = await execWrite({
    opname: "createOrder",
    op: createOrder,
    input: orderData,
  });

  if (order && order.error) {
    return {
      error: order.error,
    };
  }

  if (packageBookedSlots && packageBookedSlots.length) {
    // create package bookings here
    const bookingsList = [];
    let slotNum = 0;
    for (const apptDT of packageBookedSlots) {
      //Create Booking
      const bookingData = {
        desc: servicetype.name,
        startdate: apptDT.dateInfo.dtstamp_str,
        minutes: servicetype.minutes,
        location: location,
        companyId: company.id,
        bookingOrderId: order.id,
        orderId: order.id,
        orderType: orderType,
        isVirtual: isVirtual,
        bookingServicetypeId: servicetype.id,
        bookingProviderId: providerid,
        bookingClientId: client.id,
        bookingCompanyId: company.id,
        timeblockid: `${heldPackageSlots[slotNum].id}`,
        providerId: providerid,
        clientId: client.id,
        status: "SCHEDULED",
        timezone: bookingTz,
        TimeDisplayInfo: JSON.stringify(apptDT.dateInfo),
        manualBooking: false,
      };

      try {
        if (clientNotes) {
          bookingData.Notes = JSON.stringify([
            {
              createdBy:
                order.client.user.firstname + " " + order.client.user.lastname,
              createdAt: order.createdAt,
              notes: clientNotes,
            },
          ]);
        }
      } catch (e) {
        console.log("ERROR with adding Notes to bookingData", e);
      }

      const newbooking = await execWrite({
        opname: "createBooking",
        op: createBooking,
        input: bookingData,
      });

      if (newbooking && newbooking.error) {
        return {
          error: newbooking.error,
        };
      }
      bookingsList.push(newbooking);
      slotNum = slotNum + 1;
    }
    prepareBookingDateTimeDisplayStrings(bookingsList);
    //once the order is created and bookings are created,
    //update uisession as completed
    await createUISessionEntry({
      uiSessionId: uiSessionId,
      eventName: "COMPLETE:SYNCORDER",
      companyId: company.id,
    });

    /* Zapier Trigger - New Order Created (new-order-zapier)*/
    try {
      let orderData = {
        clientFirstName: order.client.user.firstname,
        clientLastName: order.client.user.lastname,
        clientEmail: order.client.user.emailaddress,
        clientPhoneNumber: order.client.user.mobilephone,
        providerFirstName: order.provider.firstname,
        providerLastName: order.provider.lastname,
        providerEmail: order.provider.emailaddress,
        orderNumber: order.orderNo,
        orderDescription: order.desc,
        orderAmount: order.total,
        orderCurrency: order.currency,
        orderType: order.type,
        numberOfBookings: bookingsList.length,
        service: bookingsList[0].desc,
        bookedBy: "Client",
        createdAt: new Date(order.createdAt).toLocaleString("en-US"),
      };
      if (order.type === "ONGOING") {
        orderData.numberOfBookings = "Recurring";
      }
      if (bookedByAdmin) {
        orderData.bookedBy = "Admin";
      }
      let hookName = "new-order-zapier";
      await TriggerManager(orderData, company.id, hookName);
    } catch (e) {
      console.log("ERROR: unable to perform triggerManager", e);
    }

    return {
      order,
      booking: bookingsList[0],
      bookingsList,
      client,
    };
  } else {
    let firstBooking;
    let bookingsList = [];
    // everything else as before goes here
    if (bookingType === "forever") {
      //Create recurring booking using recurringBookingsAPI
      const newBookingsResponse = await handleCreateRecurringBookings(
        bookingState,
        order
      );
      bookingsList = await generateBookingsList(
        newBookingsResponse.newBookings,
        bookingState.repeatingApptList
      );
      firstBooking = newBookingsResponse.newBookings;
    } else {
      //Create Booking
      const bookingData = {
        desc: servicetype.name,
        startdate: booking.dateInfo.dtstamp_str,
        minutes: servicetype.minutes,
        location: location,
        companyId: company.id,
        bookingOrderId: order.id,
        orderId: order.id,
        orderType: orderType,
        isVirtual: isVirtual,
        bookingServicetypeId: servicetype.id,
        bookingProviderId: providerid,
        bookingClientId: client.id,
        bookingCompanyId: company.id,
        timeblockid: `${heldSlots[0].id}`,
        providerId: providerid,
        clientId: client.id,
        status: "SCHEDULED",
        timezone: bookingTz,
        TimeDisplayInfo: JSON.stringify(booking.dateInfo),
        manualBooking: false,
      };

      try {
        if (clientNotes) {
          bookingData.Notes = JSON.stringify([
            {
              createdBy:
                order.client.user.firstname + " " + order.client.user.lastname,
              createdAt: order.createdAt,
              notes: clientNotes,
            },
          ]);
        }
      } catch (e) {
        console.log("ERROR with adding Notes to bookingData", e);
      }

      const newbooking = await execWrite({
        opname: "createBooking",
        op: createBooking,
        input: bookingData,
      });

      if (newbooking && newbooking.error) {
        return {
          error: newbooking.error,
        };
      }
      bookingsList = [];
      bookingsList.push(newbooking);

      firstBooking = newbooking;
      prepareBookingDateTimeDisplayStrings(bookingsList);
    }
    //once the order is created and bookings are created,
    //update uisession as completed
    await createUISessionEntry({
      uiSessionId: uiSessionId,
      eventName: "COMPLETE:SYNCORDER",
      companyId: company.id,
    });

    /* Zapier Trigger - New Order Created (new-order-zapier)*/
    try {
      let orderData = {
        clientFirstName: order.client.user.firstname,
        clientLastName: order.client.user.lastname,
        clientEmail: order.client.user.emailaddress,
        clientPhoneNumber: order.client.user.mobilephone,
        providerFirstName: order.provider.firstname,
        providerLastName: order.provider.lastname,
        providerEmail: order.provider.emailaddress,
        orderNumber: order.orderNo,
        orderDescription: order.desc,
        orderAmount: order.total,
        orderCurrency: order.currency,
        orderType: order.type,
        numberOfBookings: bookingsList.length,
        service: bookingsList[0].desc,
        bookedBy: "Client",
        createdAt: new Date(order.createdAt).toLocaleString("en-US"),
      };
      if (order.type === "ONGOING") {
        orderData.numberOfBookings = "Recurring";
      }
      if (bookedByAdmin) {
        orderData.bookedBy = "Admin";
      }
      let hookName = "new-order-zapier";
      await TriggerManager(orderData, company.id, hookName);
    } catch (e) {
      console.log("ERROR: unable to perform triggerManager", e);
    }

    return {
      order,
      booking: firstBooking,
      bookingsList,
      client,
    };
  }
};

async function handleCreateRecurringBookings(bookingState, order) {
  try {
    let recurBookingsResponse;
    let body = {
      companyId: bookingState.company.id,
      orderId: order.id,

      selectedSlot: bookingState.selectedslot,
      bookingData: {
        latitude: bookingState.isRemoteLocation
          ? bookingState.remoteAddressCoordinates.lat
          : bookingState.companyLocationCoordinates
          ? bookingState.companyLocationCoordinates.lat
          : null,
        longitude: bookingState.isRemoteLocation
          ? bookingState.remoteAddressCoordinates.lng
          : bookingState.companyLocationCoordinates
          ? bookingState.companyLocationCoordinates.lng
          : null,
        bookingBookedById: bookingState.bookingUser.username,
        location: bookingState.location,
        locationId:
          bookingState.locationId !== undefined && bookingState.locationId
            ? bookingState.locationId
            : "",
        serviceType: bookingState.serviceType,
        client: {
          firstName: bookingState.client.user.firstname,
          lastName: bookingState.client.user.lastname,
          id: bookingState.client.id,
        },
        provider: {
          firstName: bookingState.provider.firstname,
          lastName: bookingState.provider.lastname,
          id: bookingState.provider.id,
          timezone: bookingState.provider.timezone,
        },
        isVirtual:
          bookingState.isVirtual !== undefined && bookingState.isVirtual
            ? true
            : false,
        isRemoteLocation:
          bookingState.isRemoteLocation !== undefined &&
          bookingState.isRemoteLocation
            ? true
            : false,
        remoteAddressCoordinates:
          bookingState.isRemoteLocation !== undefined &&
          bookingState.isRemoteLocation
            ? {
                lat: bookingState.remoteAddressCoordinates.lat,
                lng: bookingState.remoteAddressCoordinates.lng,
              }
            : bookingState.companyLocationCoordinates
            ? bookingState.companyLocationCoordinates
            : null,
        dayCount: bookingState.dayCount,
        dayType: bookingState.dayType,
        daysOfWeek: bookingState.daysOfWeek,
        rrule: bookingState.rrule,
      },
    };
    if (bookingState.clientNotes) {
      body.bookingData.Notes = JSON.stringify([
        {
          createdBy:
            bookingState.client.user.firstname +
            " " +
            bookingState.client.user.lastname,
          createdAt: new Date(),
          notes: bookingState.clientNotes,
        },
      ]);
    }
    if (bookingState.heldSlots) {
      let heldSlots = getHeldSlotsForRecurringAPI(
        bookingState.apptDates,
        bookingState.heldSlots
      );
      body.bookingData.heldSlots = heldSlots;
    }

    recurBookingsResponse = await API.post(
      "recurringBookingsApi",
      "/create-recurring-bookings",
      {
        body,
      }
    );
    return recurBookingsResponse.response;
  } catch (e) {
    console.log("error while creating recurring bookings", e);
  }
}

function getHeldSlotsForRecurringAPI(apptDates, heldSlots) {
  let heldSlotsList = [];
  let apptDatesList = apptDates;
  apptDatesList.unshift(heldSlots[0]); //we add the first heldSlots object to apptDates becasuse apptDates originally does not include the first booking
  for (let i = 0; i < apptDatesList.length; i++) {
    let isValid = true;
    let hasAlternate = false;
    if (i > 0) {
      const apptDate = apptDatesList[i];
      isValid = apptDate ? apptDate.validity.isValid : isValid;
      hasAlternate = apptDate ? apptDate.validity.hasAlternate : hasAlternate;
    }

    if (isValid || (hasAlternate && !isValid)) {
      const currentHeldSlot =
        i > 0
          ? heldSlots.find(
              (slot) => slot.startDate === apptDatesList[i].dateInfo.dt_disp
            )
          : heldSlots[0];
      const bookingObject = {
        dt_disp: moment(currentHeldSlot.sdtutc).format("YYYY-MM-DD"),
        dtstamp_str: currentHeldSlot.sdtutc,
        dt_long_disp: moment(currentHeldSlot.sdtutc).format(
          "dddd, MMMM D, YYYY"
        ),
        partial_dt_full_disp: moment(currentHeldSlot.sdtutc).format(
          "ddd, MMM D, YYYY"
        ),
        partial_en_slot_disp: moment(currentHeldSlot.sdtutc).format(
          "ddd, MMM D"
        ),
        timeblockid: currentHeldSlot.id,
      };
      heldSlotsList.push(bookingObject);
    } else {
      heldSlotsList.push(null);
    }
  }
  return heldSlotsList;
}

/**
 * Generates a new array of booking objects based on the given bookingObject
 * and repeatApptList. The first object in the new array is the original
 * bookingObject, and subsequent objects are based on the repeatApptList with
 * specific attributes updated in the TimeDisplayInfo.
 *
 * @param {Object} bookingObject - The original booking object.
 * @param {Array} repeatApptList - An array of dateTimeObjects for different days.
 * @returns {Array} An array of booking objects with updated attributes.
 */
async function generateBookingsList(bookingObject, repeatApptList) {
  // Initialize the new array
  let bookingsList = [];

  // Iterate over each dateTimeObject in repeatApptList
  for (const dateTimeObject of repeatApptList) {
    // Clone the bookingObject to avoid modifying the original object
    let newBooking = {
      provider: bookingObject.provider,
      TimeDisplayInfo: JSON.parse(
        JSON.stringify(bookingObject.TimeDisplayInfo)
      ),
      isVirtual: bookingObject.isVirtual,
      location: bookingObject.location,
    };

    // Update attributes in TimeDisplayInfo
    newBooking.TimeDisplayInfo.dt_disp = dateTimeObject.dateInfo.dt_disp;
    newBooking.TimeDisplayInfo.dtstamp_str =
      dateTimeObject.dateInfo.dtstamp_str;
    newBooking.TimeDisplayInfo.dt_long_disp =
      dateTimeObject.dateInfo.dt_long_disp;
    newBooking.TimeDisplayInfo.dt_full_disp =
      dateTimeObject.dateInfo.partial_dt_full_disp;
    newBooking.TimeDisplayInfo.en_slot_disp =
      dateTimeObject.dateInfo.partial_en_slot_disp;

    // Push the modified object to the new array
    bookingsList.push(newBooking);
  }

  // Add the bookingObject as the first booking in the List
  bookingsList.unshift(bookingObject);

  return bookingsList;
}

function prepareBookingDateTimeDisplayStrings(bookingsList) {
  if (bookingsList && bookingsList.length) {
    for (let bk of bookingsList) {
      bk.TimeDisplayInfo = JSON.parse(bk.TimeDisplayInfo);
    }
  }
}

async function getNewOrderNo(companyId) {
  const ordNoResp = await API.get("bookingapi", "/id", {
    queryStringParameters: {
      companyId,
      idName: "ORDERNO",
    },
  });
  if (ordNoResp && ordNoResp.success) return ordNoResp.id;
  else {
    return Number(new String(Date.now()).slice(-8));
  }
}

function getBookingAddress(bookingState) {
  let bookingAddress = {};
  if (bookingState.appointmentLocation === "remote") {
    bookingAddress.addrOneLine = bookingState.location;
    bookingAddress.state = bookingState.remoteAddressState;
    bookingAddress.countryCode = bookingState.remoteAddressCountryShort;
    bookingAddress.postalCode = bookingState.remoteAddressPostalCode;
    bookingAddress.country = bookingState.remoteAddressCountry;
  } else {
    bookingAddress.addrOneLine = bookingState.location;
    bookingAddress.state = bookingState.province;
    bookingAddress.countryCode = bookingState.countryShort;
    bookingAddress.postalCode = bookingState.postalCode;
    bookingAddress.country = bookingState.country;
  }
  return bookingAddress;
}

const createClientRecord = async (user, company) => {
  const clientData = {
    userId: user.id,
    currency: company.currency ? company.currency : "CAD",
    companyId: company.id,
    clientUserId: user.id,
    clientCompanyId: company.id,
    accountbalance: 0.0,
  };

  let client = await execWrite({
    opname: "createClient",
    op: createClient,
    input: clientData,
  });

  if (client && client.error) {
    return {
      error: client.error,
    };
  } else return client;
};

const holdSlotForPackageAppointments = async ({
  uiSessionId,
  company,
  servicetype,
  booking,
  geoLoc,
  locationId,
  providerId,
}) => {
  if (booking) {
    const tbendtime = getTimeblockEndTime(servicetype, booking);
    let bookedtimeblockData = {
      companyId: company.id,
      startDate: getAWSDate(booking.date),
      startTime: booking.time,
      endTime: tbendtime,
      type: "BOOKED",
      locationId,
      status: "PENDING",
      geoLoc,
      tz: booking.bookingTz,
      sdtutc: booking.dateInfo.dtstamp_str,
      providerId,
    };
    const tb = await createTimeblock(bookedtimeblockData);
    try {
      await API.post("bookingapi", "/pendingslotstracking", {
        body: {
          heldSlots: [
            {
              id: tb.id,
              scheduleinfo: tb.scheduleinfo,
              status: tb.status,
            },
          ],
          uiSessionId: uiSessionId,
          companyId: company.id,
        },
      });
    } catch (e) {
      console.log(
        "holdSlotForPackageAppointments queueing heldslots for tracking error",
        e
      );
    }
    return tb;
  }
  return null;
};

const holdSlotsForAppointments = async ({
  uiSessionId,
  scheduleId,
  providerId,
  company,
  servicetype,
  booking,
  hasrepeatingappt,
  repeatingapptinfo,
  geoLoc,
  locationId,
}) => {
  const returnTimeblockList = [];
  // Create Timeblock
  if (booking) {
    const tbendtime = getTimeblockEndTime(servicetype, booking);
    let bookedtimeblockData = {
      companyId: company.id,
      startDate: getAWSDate(booking.date),
      startTime: booking.time,
      endTime: tbendtime,
      type: "BOOKED",
      scheduleId,
      providerId,
      status: "PENDING",
      locationId,
      geoLoc,
      tz: booking.bookingTz,
      sdtutc: booking.dateInfo.dtstamp_str,
    };
    const tb = await createTimeblock(bookedtimeblockData);
    returnTimeblockList.push(tb);
  }
  //this part is only done for recurring bookings
  if (hasrepeatingappt && repeatingapptinfo.apptlist) {
    for (const apptDT of repeatingapptinfo.repeatingApptList) {
      // Create Timeblock
      const tbendtime = getTimeblockEndTimeOfAppt(servicetype, apptDT.date);
      let bookedtimeblockData = {
        companyId: company.id,
        startDate: getAWSDate(apptDT.date),
        startTime: getBookingTimeOfAppt(apptDT.date),
        endTime: tbendtime,
        type: "BOOKED",
        scheduleId,
        providerId,
        status: "PENDING",
        geoLoc,
        locationId,
        tz: apptDT.dateInfo.tz,
        sdtutc: apptDT.dateInfo.dtstamp_str,
      };
      const tb = await createTimeblock(bookedtimeblockData);
      returnTimeblockList.push({ ...tb, date: apptDT.date });
    }
  }
  if (returnTimeblockList && returnTimeblockList.length)
    try {
      await API.post("bookingapi", "/pendingslotstracking", {
        body: {
          heldSlots: returnTimeblockList.map((ps) => {
            return {
              id: ps.id,
              scheduleinfo: ps.scheduleinfo,
              status: ps.status,
            };
          }),
          uiSessionId: uiSessionId,
          companyId: company.id,
        },
      });
    } catch (e) {
      console.log(" queueing heldslots for tracking error", e);
    }

  return {
    heldSlots: returnTimeblockList,
  };
};

const calculateChargeBreakup = (basePrice, company, isPkgCredit, bs) => {
  const { countryCode, stateCode } = getTaxJurisdiction(bs, company);
  const jurisdictionTaxRate = getServiceTaxRate(countryCode, stateCode);
  let taxableAmount = 0;
  let taxAmount = 0;
  let taxRate;
  if (bs.sdt === "package") {
    if (bs.boughtpackage !== null) {
      const {
        regionalServicePrice,
        boughtpackage: {
          servicetype: { taxexempt, TaxOverride: taxOverrideJson },
          packagetype,
          price,
          quantity,
          discount,
        },
      } = bs;
      let taxOverride;
      try {
        taxOverride = JSON.parse(taxOverrideJson);
      } catch {
        console.log("Unable to parse tax override");
      }
      if (taxexempt === false) {
        taxableAmount =
          packagetype === "DOLLAR"
            ? price
            : quantity * regionalServicePrice * (1 - discount / 100);
      }
      taxRate =
        !isNaN(parseFloat(taxOverride)) && isFinite(taxOverride)
          ? parseFloat(taxOverride)
          : jurisdictionTaxRate;
      taxAmount = (taxableAmount * taxRate) / 100;
    }
  } else {
    const {
      regionalServicePrice1,
      regionalServicePrice2,
      regionalServicePrice3,
      serviceQty1,
      serviceQty2,
      serviceQty3,
      serviceType1,
      serviceType2,
      serviceType3,
    } = bs;
    const { taxexempt: taxexempt1, TaxOverride: taxOverride1Json1 } =
      serviceType1 || {};
    const { taxexempt: taxexempt2, TaxOverride: taxOverride1Json2 } =
      serviceType2 || {};
    const { taxexempt: taxexempt3, TaxOverride: taxOverride1Json3 } =
      serviceType3 || {};
    let taxOverride1, taxOverride2, taxOverride3;
    let taxRate1, taxRate2, taxRate3;
    let taxableAmount1, taxableAmount2, taxableAmount3;
    try {
      taxOverride1 = JSON.parse(taxOverride1Json1);
    } catch {
      console.log("Unable to parse tax override");
    }
    try {
      taxOverride2 = JSON.parse(taxOverride1Json2);
    } catch {
      console.log("Unable to parse tax override");
    }
    try {
      taxOverride3 = JSON.parse(taxOverride1Json3);
    } catch {
      console.log("Unable to parse tax override");
    }
    if (taxexempt1 !== true && serviceType1) {
      taxableAmount1 = regionalServicePrice1 * serviceQty1;
      taxableAmount += taxableAmount1;
      taxRate1 =
        !isNaN(parseFloat(taxOverride1)) && isFinite(taxOverride1)
          ? parseFloat(taxOverride1)
          : jurisdictionTaxRate;
      taxAmount += (taxableAmount1 * taxRate1) / 100;
    }
    if (taxexempt2 !== true && serviceType2) {
      taxableAmount2 = regionalServicePrice2 * serviceQty2;
      taxableAmount += taxableAmount2;
      taxRate2 =
        !isNaN(parseFloat(taxOverride2)) && isFinite(taxOverride2)
          ? parseFloat(taxOverride2)
          : jurisdictionTaxRate;
      taxAmount += (taxableAmount2 * taxRate2) / 100;
    }
    if (taxexempt3 !== true && serviceType3) {
      taxableAmount3 = regionalServicePrice3 * serviceQty3;
      taxableAmount += taxableAmount2;
      taxRate3 =
        !isNaN(parseFloat(taxOverride3)) && isFinite(taxOverride3)
          ? parseFloat(taxOverride3)
          : jurisdictionTaxRate;
      taxAmount += (taxableAmount3 * taxRate3) / 100;
    }
    // detects is there are any different non undefiend tax rate in which case the rate is variable
    taxRate = [taxRate1, taxRate2, taxRate3].reduce(
      (acc, rate) =>
        rate !== undefined && rate !== acc ? VARIABLE_TAX_RATE : acc,
      taxRate1
    );
  }

  const charge = {
    subtotal: Number.parseFloat(basePrice),
    servicechargeamt: 0.0,
    total: 0.0,
    discount: 0.0,
  };

  if (bs.promoData) {
    if (bs.promoData.discountType === "DOLLAR")
      charge.discount = Number(bs.promoData.discountValue);
    //assume PERCENTAGE
    else
      charge.discount =
        charge.subtotal * (Number(bs.promoData.discountValue) / 100);
  }

  if (company.addServiceFee && !isPkgCredit) {
    if (company.serviceFeeType === "PERCENTAGE") {
      charge.servicechargeamt = Number.parseFloat(
        (basePrice * company.serviceFeeAmount) / 100
      );
    } else {
      charge.servicechargeamt = Number.parseFloat(company.serviceFeeAmount);
    }
    // Service fees taxation jusrestiction based logic
    // Assume no cross border orders
    if (countryCode.toUpperCase() === "CA" && charge.servicechargeamt > 0) {
      taxableAmount += charge.servicechargeamt;
      taxAmount += charge.servicechargeamt * (jurisdictionTaxRate / 100);
    }
  }

  charge.taxableamt = Number.parseFloat(taxableAmount);
  charge.taxamt = taxAmount;
  charge.taxrate = taxRate;

  charge.total =
    charge.subtotal + charge.servicechargeamt + charge.taxamt - charge.discount;
  return charge;
};

const updateClientPackageToPaid = async (pkg, usedQuantity) => {
  const updCliPkg = /* GraphQL */ `
    mutation UpdateClientPackage($input: UpdateClientPackageInput!) {
      updateClientPackage(input: $input) {
        id
      }
    }
  `;

  return await execWrite({
    opname: "updateClientPackage",
    op: updCliPkg,
    input: {
      id: pkg.id,
      status: "paid",
      usedQuantity,
      servicetypeId: pkg.servicetypeId,
      packageId: pkg.packageId,
      createdAt: pkg.createdAt,
    },
  });
};

function prepareOrderDesc(servicetype, booking, bookingType) {
  //For single booking.
  //TBD: For repeated booking.
  if (bookingType === "forever") {
    return `Repeating bookings for ${servicetype.name} starting from ${booking.dateInfo.dt_full_disp}`;
  } else if (bookingType === "package") {
    return `Package booking for ${servicetype.name}`;
  } else
    return `Booking for ${servicetype.name} on ${booking.dateInfo.dt_full_disp} `;
}

function getTimeblockEndTime(servicetype, booking) {
  const endtime = new Date();
  const parts = booking.time.split(":");
  endtime.setHours(Number.parseInt(parts[0]));
  endtime.setMinutes(servicetype.minutes + Number.parseInt(parts[1]));
  return `${
    endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours()
  }:${
    endtime.getMinutes() < 10
      ? "0" + endtime.getMinutes()
      : endtime.getMinutes()
  }`;
}

function getTimeblockEndTimeOfAppt(servicetype, apptDatetime) {
  const endtime = new Date(apptDatetime.getTime());
  endtime.setMinutes(servicetype.minutes + endtime.getMinutes());
  return `${
    endtime.getHours() < 10 ? "0" + endtime.getHours() : endtime.getHours()
  }:${
    endtime.getMinutes() < 10
      ? "0" + endtime.getMinutes()
      : endtime.getMinutes()
  }`;
}

function getBookingTimeOfAppt(apptDatetime) {
  return `${
    apptDatetime.getHours() < 10
      ? "0" + apptDatetime.getHours()
      : apptDatetime.getHours()
  }:${
    apptDatetime.getMinutes() < 10
      ? "0" + apptDatetime.getMinutes()
      : apptDatetime.getMinutes()
  }`;
}

async function getClientIfExists(cd) {
  const ClientByUserId = /* GraphQL */ `
    query ClientByUserId(
      $userId: String
      $id: ModelIDKeyConditionInput
      $sortDirection: ModelSortDirection
      $filter: ModelClientFilterInput
      $limit: Int
      $nextToken: String
    ) {
      clientByUserId(
        userId: $userId
        id: $id
        sortDirection: $sortDirection
        filter: $filter
        limit: $limit
        nextToken: $nextToken
      ) {
        items {
          id
          userId
          companyId
          stripeCustomerId
          defaultpartialcc
          user {
            id
            username
            emailaddress
            firstname
            lastname
            homephone
            workphone
            mobilephone
            phonepref
          }
        }
        nextToken
      }
    }
  `;

  const response = await API.graphql(
    graphqlOperation(ClientByUserId, {
      userId: cd.userid,
      filter: { companyId: { eq: cd.companyid } },
    })
  );
  let client =
    response.data.clientByUserId.items && response.data.clientByUserId.items[0];

  return client;
}

const getPaidPackages = async ({ userid, servicetypeid }) => {
  const filter = {
    and: [{ active: { ne: false } }, { status: { eq: "paid" } }],
  };

  const listClientPackagesData = await execReadBySortkey({
    op: clientPackageByUser,
    opname: "clientPackageByUser",
    id: { userId: userid },
    skey: {
      servicetypeIdPackageIdCreatedAt: {
        beginsWith: { servicetypeId: servicetypeid },
      },
    },
    filter,
    limit: 10,
  });
  if (listClientPackagesData.error) {
    return [];
  }
  return listClientPackagesData.items
    ? listClientPackagesData.items.filter(
        (cp) => cp.initialQuantity > cp.usedQuantity
      )
    : [];
};

const updateUsedQuantityOfPackage = async ({ pkg, increaseby }) => {
  //should we check if usedQuantity will be greater than initial quantity
  const {
    data: { getClientPackage: clientPackage },
  } = await API.graphql(
    graphqlOperation(getClientPackage, {
      id: pkg.id,
    })
  );
  const usedQuantity = clientPackage
    ? clientPackage.usedQuantity + increaseby
    : pkg.usedQuantity + increaseby;
  const input = {
    id: pkg.id,
    servicetypeId: pkg.servicetypeId,
    packageId: pkg.packageId,
    createdAt: pkg.createdAt,
    usedQuantity,
  };
  const response = await execWrite({
    opname: "updateClientPackage",
    op: updateClientPackage,
    input,
  });
  return response;
};

const updateOrderStatusToPaidByPkgCredit = async (orderid) => {
  return await updateOrderStatus(
    orderid,
    "PAIDBYPKGCRE",
    {
      subtotal: 0.0,
      servicechargeamt: 0.0,
      total: 0.0,
      taxamt: 0.0,
    },
    null
  );
};
const updateOrderStatusToConfirmed = async (
  orderid,
  chargeBreakup,
  receipt
) => {
  return await updateOrderStatus(orderid, "CONFIRMED", chargeBreakup, receipt);
};
const updateOrderStatusToPaid = async (orderid, chargeBreakup, receipt) => {
  return await updateOrderStatus(orderid, "PAID", chargeBreakup, receipt);
};
const updateOrderStatusToClosed = async (orderid) => {
  return await updateOrderStatus(orderid, "CLOSED", null, null);
};
const updateOrderStatus = async (orderid, status, chargeBreakup, receipt) => {
  const updOrder = /* GraphQL */ `
    mutation UpdateOrder($input: UpdateOrderInput!) {
      updateOrder(input: $input) {
        id
      }
    }
  `;
  return await execWrite({
    opname: "updateOrder",
    op: updOrder,
    input: {
      id: orderid,
      status,
      ...(receipt
        ? {
            orderReceipt: receipt,
          }
        : {}),
      ...(chargeBreakup
        ? {
            subtotal: chargeBreakup.subtotal,
            total: chargeBreakup.total,
            servicechargeamt: chargeBreakup.servicechargeamt,
            taxamt: chargeBreakup.taxamt,
          }
        : {}),
    },
  });
};

const updateClientBalanceTo = async (clientId, balance) => {
  const updateClient = `mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
    }
  }`;
  return await execWrite({
    opname: "updateClient",
    op: updateClient,
    input: {
      id: clientId,
      accountbalance: balance,
    },
  });
};

const cancelBooking = async (booking) => {
  const updBooking = /* GraphQL */ `
    mutation UpdateBooking($input: UpdateBookingInput!) {
      updateBooking(input: $input) {
        id
        status
        timeblockid
        providerId
        clientId
        companyId
      }
    }
  `;
  const bkgresp = await execWrite({
    opname: "updateBooking",
    op: updBooking,
    input: {
      id: booking.id,
      status: "CANCELLED",
      cancelledAt: booking.cancelledAt,
      bookingCancelledById: booking.cancelledBy,
    },
  });

  if (bkgresp && bkgresp.timeblockid) {
    const tbpksk = bkgresp.timeblockid.split("::");
    await execWrite({
      opname: "updateProviderSchedule",
      op: updateProviderSchedule,
      input: {
        id: tbpksk[0],
        scheduleinfo: tbpksk[1],
        status: "CANCELLED",
      },
    });
  }
  return bkgresp;
};

const saveBillingProgress = async (input) => {
  const {
    currency,
    company,
    order,
    booking,
    client,
    user,
    serviceType,
    chargeBreakup,
  } = input;
  const ts = getCurrentDate();
  const jsonData = JSON.stringify({
    companyId: company.id,
    orderId: order.id,
    bookingId: booking.id,
    userId: user.id,
    userEmailAddress: user.emailaddress,
    currency: currency,
    stripeCustomerId: client.stripeCustomerId,
    clientId: client.id,
    serviceTypeName: serviceType.name,
    bookingTimeblockId: booking.timeblockid,
    appointmentDate: booking.startdate,
    timestamp: ts,
    chargeBreakup,
  });

  const bpData = {
    companyId: company.id,
    dateTime: new Date(),
    status: "QUEUED",
    data: jsonData,
  };

  try {
    const bp = await execWrite({
      opname: "createBillingProgress",
      op: createBillingProgress,
      input: bpData,
    });

    if (bp && bp.error) {
      return {
        error: bp.error,
        success: false,
      };
    } else {
      return { success: true };
    }
  } catch (e) {
    return { success: false, error: e };
  }
};
const getCurrentDate = () => {
  return moment.utc().format("YYYY-MM-DDTHH-mm-ss.SSS");
};

const updateHeldSlotsToConfirmed = async (heldSlots) => {
  for (let hs of heldSlots) {
    const tbpksk = hs.id.split("::");

    await execWrite({
      opname: "updateProviderSchedule",
      op: updateProviderSchedule,
      input: {
        id: tbpksk[0],
        scheduleinfo: tbpksk[1],
        status: "CONFIRMED",
      },
    });
  }
};

const deleteHeldSlots = async (heldSlots) => {
  for (let hs of heldSlots) {
    const tbpksk = hs.id.split("::");
    await execWrite({
      opname: "deleteProviderSchedule",
      op: deleteProviderSchedule,
      input: {
        id: tbpksk[0],
        scheduleinfo: tbpksk[1],
      },
    });
  }
};

async function sendProviderBookingConfirmation({
  provider,
  client,
  company,
  serviceType,
  bookings,
  orderNo,
  orderType,
  orderNotes,
  wdDisplay,
}) {
  const providerConfirmation = await ProviderBookingConfirmation({
    provider,
    client,
    company,
    serviceType,
    bookings,
    orderNo,
    orderType,
    orderNotes,
    wdDisplay,
  });
  if (providerConfirmation.length > 0)
    await handleSendEmail(
      `You have received a new booking from ${company.name}: Ref No. ${orderNo}`,
      providerConfirmation,
      [provider.emailaddress],
      [],
      [],
      company.replyemailaddress,
      company.name
    );
}

async function sendOrderReceipt(orderId) {
  try {
    await API.post("bookingapi", "/booking/receipt-email", {
      body: { orderId },
    });
  } catch (e) {
    console.log("ERROR generating receipt email", e);
  }
}

async function saveClientPackage(user, bookingState) {
  try {
    const pkg = bookingState.boughtpackage;

    let input = {
      userId: user.username,
      clientPackageUserId: user.username,
      clientPackageServicetypeId: bookingState.serviceType.id,
      clientId: bookingState.client.id,
      clientPackageClientId: bookingState.client.id,
      servicetypeId: bookingState.serviceType.id,
      clientPackagePackageId: pkg.id,
      packageId: pkg.id,
      initialQuantity: pkg.quantity,
      usedQuantity: 0,
      status: "pending",
      active: true,
      createdAt: new Date().toISOString(),
    };
    const result = await API.graphql(
      graphqlOperation(createClientPackage, { input })
    );

    return {
      success: true,
      pkg: result.data.createClientPackage,
    };
  } catch (err) {
    console.log("Create package error");
    return {
      success: false,
    };
  }
}

async function fetchPackages(companyId, serviceTypeId) {
  // check for available active packages for this company and service type

  let listPackagesData = await execReadBySortkey({
    opname: "packageByCompany",
    op: packageByCompany,
    id: { companyId: companyId },
    skey: {
      servicetypeIdCreatedAt: {
        beginsWith: {
          servicetypeId: serviceTypeId,
        },
      },
    },
    filter: {
      and: [{ active: { ne: false } }, { deleted: { ne: true } }],
    },
    sortDirection: "DESC",
  });

  if (listPackagesData.items && listPackagesData.items.length > 0) {
    listPackagesData.items.sort((p1, p2) => p1.quantity - p2.quantity);
  }
  return listPackagesData.items;
}

async function fetchRegionalPricing(companyId, serviceTypeId) {
  // check for available regional pricing for this company and service type

  let listRegionalPricingData = await execReadBySortkey({
    opname: "regionalPricingByCompanyServiceType",
    op: regionalPricingByCompanyServiceType,
    id: { companyId: companyId },
    skey: { servicetypeId: { eq: serviceTypeId } },
    filter: {
      and: [{ active: { ne: false } }, { deleted: { ne: true } }],
    },
    sortDirection: "DESC",
  });

  return listRegionalPricingData.items;
}

// gets correct price based on regional pricing
async function getPrice(bookingState, serviceType) {
  const price = serviceType.price;

  // virtual appts have no regional pricing
  if (bookingState.isVirtual) return price;

  const regpri = await fetchRegionalPricing(
    bookingState.company.id,
    serviceType.id
  );

  let country = bookingState.isRemoteLocation
    ? bookingState.remoteAddressCountryShort
    : bookingState.countryShort;
  let prov = bookingState.isRemoteLocation
    ? bookingState.remoteAddressState
    : bookingState.province;
  let postalCode = bookingState.isRemoteLocation
    ? bookingState.remoteAddressPostalCode
    : bookingState.postalCode;

  // filters regional pricing into separate arrays by type
  let countryRegPri = regpri.filter((o) => o.pricingtype === "COUNTRY");
  let provRegPri = regpri.filter((o) => o.pricingtype === "PROVINCE");
  let postalRegPri = regpri.filter((o) => o.pricingtype === "POSTALCODE");
  // further filters through postalcode regional pricing array to find matching postal code
  if (postalCode)
    postalRegPri = postalRegPri.filter((o) =>
      o.postalcodes.find(
        (p) =>
          p.replace(/\s+/g, "").toUpperCase() ===
          postalCode.replace(/\s+/g, "").toUpperCase()
      )
    );

  // if there is postalcode regional pricing and it matches the booking postal code, choose that price
  if (postalCode && postalRegPri.length) {
    return postalRegPri[0].price;
  }
  // if there is province regional pricing and it matches the booking province, choose that price
  else if (
    provRegPri.length &&
    provRegPri.find((o) => o.province.trim() === prov)
  ) {
    return provRegPri.find((o) => o.province.trim() === prov).price;
  }
  // if there is country regional pricing and it matches the booking country, choose that price
  else if (
    countryRegPri.length &&
    countryRegPri.find((o) => o.country.trim() === country)
  ) {
    return countryRegPri.find((o) => o.country.trim() === country).price;
  }
  // if no regional pricing, stick with original service price
  else {
    return price;
  }
}

// populates any description fields with the proper price per session values
function getDescription(val, price, currency) {
  // note that dollar packages use val.price, while percentage packages. use passed in price value
  if (!val.desc.includes("$[price]") && !val.desc.includes("[price]"))
    return val.desc;
  else if (val.packagetype === "DOLLAR") {
    return val.desc
      .replace(/\$/gi, "")
      .replace(
        "[price]",
        `${getCurrencySymbol(currency)}${(val.price / val.quantity).toFixed(2)}`
      );
  } else if (val.packagetype === "PERCENTAGE") {
    let pricePerSession =
      (val.quantity * price * (1 - val.discount / 100)) / val.quantity;
    return val.desc
      .replace(/\$/gi, "")
      .replace(
        "[price]",
        `${getCurrencySymbol(currency)}${pricePerSession.toFixed(2)}`
      );
  } else {
    return "PACKAGE TYPE MISSING";
  }
}

async function fetchCompanyLocation(locationId) {
  const getCompanyLocationData = await API.graphql(
    graphqlOperation(getCompanyLocation, { id: locationId })
  );
  return getCompanyLocationData.data.getCompanyLocation;
}

function getStripeAmount(num) {
  let num_check = (parseFloat(num) * 100).toString();
  const decimal_pos = num_check.indexOf(".");
  if (decimal_pos === -1) {
    return parseInt(num_check);
  } else {
    const rounded_num = num_check.charAt(decimal_pos + 1);
    if (parseInt(rounded_num) >= 5) {
      return parseInt(num_check) + 1;
    } else if (parseInt(rounded_num) < 5) {
      return parseInt(num_check);
    }
  }
}

const saveBookingRequest = async (
  bookingState,
  client,
  guestCheckoutInfoForBookingReq
) => {
  // Read client:
  if (!client) {
    let clientRecord = await getClientIfExists({
      userid: bookingState.bookingUser.username,
      companyid: bookingState.company.id,
    });
    client = clientRecord;
  }
  let savedNotDisplayedProviderData = [];
  let savedDisplayedProviderData = [];
  if (
    bookingState.savedNotDisplayedProviderData &&
    bookingState.savedNotDisplayedProviderData.length > 0
  ) {
    let usethese = [];
    if (bookingState.savedNotDisplayedProviderData.length > 10) {
      usethese = bookingState.savedNotDisplayedProviderData.slice(0, 10);
    } else usethese = bookingState.savedNotDisplayedProviderData;
    savedNotDisplayedProviderData = usethese.map((p) => {
      return {
        id: p.id,
        firstname: p.firstname,
        lastname: p.lastname,
        active: p.active,
        maxtraveltype: p.maxtraveltype,
        emailaddress: p.emailaddress,
        phone: p.phone,
        pictures3key: p.pictures3key,
        virtualMeetingUserId: p.virtualMeetingUserId,
        vmlink: p.vmlink,
        SBs: p.SBs?.length
          ? p.SBs.map((sb) => {
              return {
                startDate: sb.startDate,
                endDate: sb.endDate,
                startTime: sb.startTime,
                endTime: sb.endTime,
                weekDays: sb.weekDays,
              };
            })
          : [],
        timezone: p.timezone,
      };
    });
  }

  if (
    bookingState.savedDisplayedProviderData &&
    bookingState.savedDisplayedProviderData.length > 0
  ) {
    let usethese = [];
    if (bookingState.savedDisplayedProviderData.length > 10) {
      usethese = bookingState.savedDisplayedProviderData.slice(0, 10);
    } else usethese = bookingState.savedDisplayedProviderData;

    savedDisplayedProviderData = usethese.map((p) => {
      return {
        id: p.id,
        firstname: p.firstname,
        lastname: p.lastname,
        active: p.active,
        maxtraveltype: p.maxtraveltype,
        emailaddress: p.emailaddress,
        phone: p.phone,
        pictures3key: p.pictures3key,
        virtualMeetingUserId: p.virtualMeetingUserId,
        vmlink: p.vmlink,
        SBs: p.SBs?.length
          ? p.SBs.map((sb) => {
              return {
                startDate: sb.startDate,
                endDate: sb.endDate,
                startTime: sb.startTime,
                endTime: sb.endTime,
                weekDays: sb.weekDays,
              };
            })
          : [],
        timezone: p.timezone,
      };
    });
  }
  //const orderNo = await getNewOrderNo(bookingState.company.id);
  let br = {
    geoLoc: bookingState.isRemoteLocation
      ? bookingState.remoteAddressCoordinates
      : bookingState.companyLocationCoordinates
      ? bookingState.companyLocationCoordinates
      : null,
    bookingTz: bookingState.bookingTz,
    clientNotes: bookingState.clientNotes,
    extraAvailabilityNotes: bookingState.extraAvailabilityNotes,
    //orderNo,
    bookingAddress: getBookingAddress(bookingState),
    location: bookingState.location,
    locationId: bookingState.locationId,
    tryOtherProviders: bookingState.bookedSuggestedSlots
      ? false
      : bookingState.tryOtherProviders,
    currency: bookingState.currency,
    client,
    dir: {
      notdisplayed: savedNotDisplayedProviderData,
      displayed: savedDisplayedProviderData,
    },
    companyId: bookingState.company.id,
    serviceType: {
      id: bookingState.serviceType.id,
      name: bookingState.serviceType.name,
      price: bookingState.serviceType.price,
      minutes: bookingState.serviceType.minutes,
    },
    serviceQty1: bookingState.serviceQty1,
    serviceQty2: bookingState.serviceQty2,
    serviceQty3: bookingState.serviceQty3,
    serviceType1: bookingState.serviceType1,
    serviceType2: bookingState.serviceType2,
    serviceType3: bookingState.serviceType3,
    user: client.user,
    address: bookingState.address,
    isVirtual: bookingState.isVirtual,
    isRemoteLocation: bookingState.isRemoteLocation,
    appointmentLocation: bookingState.appointmentLocation,
    datefilter: bookingState.datefilter,
    provider: {
      id: bookingState.provider.id,
      emailaddress: bookingState.provider.emailaddress,
      firstname: bookingState.provider.firstname,
      lastname: bookingState.provider.lastname,
      phone: bookingState.provider.phone,
      pictures3key: bookingState.provider.pictures3key,
      virtualMeetingUserId: bookingState.provider.virtualMeetingUserId,
      vmlink: bookingState.provider.vmlink,
      permalink: bookingState.provider.permalink
        ? bookingState.provider.permalink
        : "",
      SBs: bookingState.provider.SBs.map((sb) => {
        return {
          startDate: sb.startDate,
          endDate: sb.endDate,
          startTime: sb.startTime,
          endTime: sb.endTime,
          weekDays: sb.weekDays,
        };
      }),
      timezone: bookingState.provider.timezone,
    },
    origProvider: {
      id: bookingState.provider.id,
      emailaddress: bookingState.provider.emailaddress,
      firstname: bookingState.provider.firstname,
      lastname: bookingState.provider.lastname,
      phone: bookingState.provider.phone,
      virtualMeetingUserId: bookingState.provider.virtualMeetingUserId,
      vmlink: bookingState.provider.vmlink,
      timezone: bookingState.provider.timezone,
    },
    selectedslot: {
      ...bookingState.selectedslot,
      day: bookingState.selectedslot.date.getDay(),
      tz: bookingState.bookingTz,
    },
    dayCount: bookingState.dayCount,
    dayType: bookingState.dayType,
    daysOfWeek: bookingState.daysOfWeek,
    repeatingAppointment: bookingState.packageBookedSlots?.length
      ? true
      : bookingState.repeatingAppointment,
    repeatingApptList: bookingState.packageBookedSlots?.length
      ? bookingState.packageBookedSlots.slice(1)
      : bookingState.repeatingApptList,
    rrule: bookingState.rrule ? bookingState.rrule : "",
    newclientpkgid: bookingState.boughtpackage
      ? bookingState.newclientpkg.id
      : "",
    boughtpackage: bookingState.boughtpackage,
    clientpackage: bookingState.clientpackage,
    sdt: bookingState.sdt,

    osd: bookingState.osd,
    cbu: bookingState.cbu,
    heldSlots:
      bookingState.heldSlots && bookingState.heldSlots.length
        ? bookingState.heldSlots.map((hs) => {
            return {
              id: hs.id,
              scheduleinfo: hs.scheduleinfo,
              startDate: hs.startDate,
              endDate: hs.endDate,
              startTime: hs.startTime,
              endTime: hs.endTime,
              createdAt: hs.createdAt,
              sdtutc: hs.sdtutc,
              tz: hs.tz,
            };
          })
        : bookingState.heldPackageSlots?.length
        ? bookingState.heldPackageSlots.map((hs) => {
            return {
              id: hs.id,
              scheduleinfo: hs.scheduleinfo,
              startDate: hs.startDate,
              endDate: hs.endDate,
              startTime: hs.startTime,
              endTime: hs.endTime,
              createdAt: hs.createdAt,
              sdtutc: hs.sdtutc,
              tz: hs.tz,
              en_slot_disp: hs.en_slot_disp,
            };
          })
        : [],
    serviceLocation: bookingState.serviceLocation,
    company: getOnlyNeededFields(bookingState.company),
    lastForeverApptDate: bookingState.lastForeverApptDate
      ? getAWSDate(new Date(bookingState.lastForeverApptDate))
      : "", //in YYYY-MM-DD so that lambda does not have to do this conversion
    wdDisplay: bookingState.wdDisplay,
    bookedByAdmin: bookingState.bookingOnBehalf ? true : false,
    promoData: bookingState.promoData,
    validPromocodeApplied: bookingState.validPromocodeApplied,
    actionUser: { ...bookingState.user?.attributes },
    isRepeatingAppointment:
      bookingState.repeatingAppointment ||
      bookingState.packageRecurringSelected,
  };

  if (guestCheckoutInfoForBookingReq) {
    br.guestCheckoutInfo = guestCheckoutInfoForBookingReq;
  }

  const id = `CL-${client.id}`;

  const data = JSON.stringify(br);

  const brData = {
    id,
    recordType: `REQ|${Date.now()}`,
    providerId: bookingState.provider.id,
    accepted: false,
    status: "REQUESTED",
    data,
  };

  try {
    const br = await execWrite({
      opname: "createBookingRequest",
      op: createBookingRequest,
      input: brData,
    });

    if (br && br.error) {
      return {
        error: br.error,
        success: false,
      };
    } else {
      await createUISessionEntry({
        uiSessionId: bookingState.uiSessionId,
        eventName: "COMPLETE:BR",
        companyId: bookingState.company.id,
      });
      trackOrderCompleted(bookingState, {});
      return { success: true };
    }
  } catch (e) {
    return { success: false, error: e };
  }
};

function getOnlyNeededFields(company) {
  return {
    id: company.id,
    name: company.name,
    contactname: company.contactname,
    emailaddress: company.emailaddress,
    replyemailaddress: company.replyemailaddress,
    currency: company.currency,
    currencyBasedOnLocation: company.currencyBasedOnLocation,
    addressoneline: company.addressoneline,
    street: company.street,
    city: company.city,
    state: company.state,
    country: company.country,
    postalcode: company.postalcode,
    longitude: company.longitude,
    latitude: company.latitude,
    ApptAcceptanceFlowConfig: company.ApptAcceptanceFlowConfig,
    subdomain: company.subdomain,
    tagline: company.tagline,
    logoUrl: company.logoUrl,
    billingMode: company.billingMode,
    iframeURL: company.iframeURL,
    primaryColor: company.primaryColor,
    addServiceFee: company.addServiceFee,
    serviceFeeType: company.serviceFeeType,
    serviceFeeAmount: company.serviceFeeAmount,
    taxrate: company.taxrate,
    travelTime: company.travelTime,
    countrycode3166alpha2: company.countrycode3166alpha2,
    publishableStripeKey: company.publishableStripeKey,
    offersForeverAppt: company.offersForeverAppt,
    virtualMeetingConfig: company.virtualMeetingConfig,
    BccLists: getConfigurgedEmailAddresses(company.BccLists),
    collectpayment: company.collectpayment,
    bookingIncrement: company.bookingIncrement ? company.bookingIncrement : 15,
    stripeAccount: company.stripeAccount,
    stripeConnectEnabled: company.stripeConnectEnabled,
  };
}

function getConfigurgedEmailAddresses(bccListsJson) {
  let BccLists = { forOrdSum: [] };
  if (bccListsJson && bccListsJson.length) {
    let bccListsObj = JSON.parse(bccListsJson);
    if (bccListsObj && bccListsObj.forOrdSum)
      BccLists.forOrdSum = bccListsObj.forOrdSum;
    if (bccListsObj && bccListsObj.forBookingReqCreated)
      BccLists.forBookingReqCreated = bccListsObj.forBookingReqCreated;
    if (bccListsObj && bccListsObj.forBookingReqExpired)
      BccLists.forBookingReqExpired = bccListsObj.forBookingReqExpired;
  }
  return BccLists;
}

async function readCurrency(countryCode) {
  const result = await API.graphql(
    graphqlOperation(getRefData, {
      refType: "CURRENCY",
      refName: countryCode,
    })
  );
  let val = result.data.getRefData ? result.data.getRefData.refValue : null;

  return val;
}
async function getCurrency(bookingState) {
  if (bookingState.company.currencyBasedOnLocation) {
    let countryCode;
    if (bookingState.appointmentLocation === "remote") {
      countryCode = bookingState.remoteAddressCountryShort;
    } else {
      countryCode = bookingState.countryShort;
    }

    let currency = await readCurrency(countryCode);
    if (currency) return currency;
    else return bookingState.company.currency;
  } else return bookingState.company.currency;
}

const getCurrencySymbol = (companyCurrency) => {
  switch (companyCurrency) {
    case "GBP":
      return "£";
    case "CAD":
      return "$";
    case "USD":
      return "$";
    case "AUD":
      return "$";
    default:
      return "$";
  }
};

const getOrderTrackingData = (order, bookingState, company) => {
  let {
    providerId,
    firstname,
    lastname,
    emailaddress,
    maxtraveltype,
    offersVirtualServices,
  } = bookingState.provider;
  let { id, name, categoryId, categoryName, price, minutes } =
    bookingState.serviceType;
  return {
    companyId: company.id,
    companyName: company.name,
    provider: {
      providerId,
      firstname,
      lastname,
      emailaddress,
      maxtraveltype,
      offersVirtualServices,
    },
    serviceType: { id, name, categoryId, categoryName, price, minutes },
    location: bookingState.location,
    bookingAddress: order.bookingAddress,
    orderSummary: bookingState.osd,
    orderId: order.id,
    orderType: order.type,
  };
};

function totalRegionalPrice(bs) {
  if (bs.numServices === 1) {
    return bs.regionalServicePrice1 * bs.serviceQty1;
  }
  if (bs.numServices === 2) {
    return (
      bs.regionalServicePrice1 * bs.serviceQty1 +
      bs.regionalServicePrice2 * bs.serviceQty2
    );
  }
  if (bs.numServices === 3) {
    return (
      bs.regionalServicePrice1 * bs.serviceQty1 +
      bs.regionalServicePrice2 * bs.serviceQty2 +
      bs.regionalServicePrice3 * bs.serviceQty3
    );
  }
}

function getTaxJurisdiction(bs, company) {
  const taxJurisdiction = { countryCode: "", stateCode: "" };
  if (bs.appointmentLocation === "remote") {
    taxJurisdiction.countryCode = bs.remoteAddressCountryShort;
    taxJurisdiction.stateCode = bs.remoteAddressState;
  }
  if (bs.appointmentLocation === "local") {
    taxJurisdiction.countryCode = company.countrycode3166alpha2;
    taxJurisdiction.stateCode = company.state;
  }
  return taxJurisdiction;
}

function createOsd(bs, company) {
  const osd = { rows: [] };
  let creditsRemaining = 0;
  let cbu = {};
  let boughtPackage = bs.boughtpackage;
  //  existing pkg
  if (bs.sdt === "package") {
    const numofappts = bs.packageBookedSlots?.length
      ? bs.packageBookedSlots.length
      : 0;
    const unusedSessions = bs.maxSlotsToBeBooked;
    if (bs.clientpackage) {
      osd.rows.push({
        service: `Existing package of ${bs.clientpackage.initialQuantity} sessions`,
        qty: `${numofappts} of ${
          bs.clientpackage.initialQuantity - bs.clientpackage.usedQuantity
        } credits used`,
        price: `${getCurrencySymbol(bs) + Number.parseFloat(0).toFixed(2)}`,
        newService: `${bs.clientpackage.usedQuantity}/${bs.clientpackage.initialQuantity} credits remaining from a previously purchased package`,
        createdAt: bs.clientpackage.createdAt,
      });
      cbu = calculateChargeBreakup(0, company, true, bs);
      creditsRemaining =
        bs.clientpackage.initialQuantity -
        bs.clientpackage.usedQuantity -
        numofappts;
    }
    if (boughtPackage) {
      // calculate subtotal based on package type
      // discount for the taxableAmount is calculated in the calculateChargeBreakup function
      // dollar packages use the original price value (unchanged by regional pricing) in the boughtpackage object
      // percentage packages use the regionalServicePrice value
      const subtotal =
        boughtPackage.packagetype === "DOLLAR"
          ? boughtPackage.price
          : boughtPackage.quantity *
            bs.regionalServicePrice *
            (1 - boughtPackage.discount / 100);
      const description = getDescription(
        boughtPackage,
        bs.regionalServicePrice,
        bs.currency
      );

      osd.rows.push({
        service: description,
        qty: `${unusedSessions} / ${boughtPackage.quantity} sessions remaining`,
        price: `${
          getCurrencySymbol(bs) + Number.parseFloat(subtotal).toFixed(2)
        }`,
      });
      cbu = calculateChargeBreakup(subtotal, company, false, bs);
    }
  } else {
    // no pkg
    const service = bs.serviceType.name;
    const qty = 1; //single or forever
    let price = `${
      getCurrencySymbol(bs) +
      Number.parseFloat(bs.regionalServicePrice * qty).toFixed(2)
    }`;
    price = formatterCurr(bs.currency, bs.regionalServicePrice);
    osd.rows.push({ service, qty, price });
    // }
    cbu = calculateChargeBreakup(
      bs.regionalServicePrice * qty,
      company,
      false,
      bs
    );
  }

  osd["subtotal"] = formatterCurr(bs.currency, cbu.subtotal);
  osd["servicefee"] = formatterCurr(bs.currency, cbu.servicechargeamt);
  osd["taxrate"] = cbu.taxrate;
  osd["taxamount"] = formatterCurr(bs.currency, cbu.taxamt);
  osd["total"] = formatterCurr(bs.currency, cbu.total);
  osd["taxable"] = formatterCurr(bs.currency, cbu.taxableamt);
  if (cbu.discount) {
    osd["discount"] = formatterCurr(bs.currency, cbu.discount);
    osd["promoData"] = {
      name: bs.promoData?.name,
      promocode: bs.promoData?.promocode,
    };
  }
  bs.osd = osd;
  bs.cbu = cbu;

  return {
    od: osd,
    creditsRemaining,
    taxExemption: cbu.taxableamt !== cbu.subtotal,
  };
}

async function findOrCreateService(bs) {
  if (bs.numServices === 1 && bs.serviceQty1 === 1) {
    return {
      ...bs.serviceType1,
      units: bs.serviceQty1,
    };
  }

  if (
    (bs.company.multipleServices && bs.numServices > 1) ||
    (bs.company.multipleQty && bs.serviceQty1 > 1)
  ) {
    const tempArr = [];
    if (bs.serviceType1)
      tempArr.push({ ...bs.serviceType1, units: bs.serviceQty1 });
    if (bs.serviceType2)
      tempArr.push({ ...bs.serviceType2, units: bs.serviceQty2 });
    if (bs.serviceType3)
      tempArr.push({ ...bs.serviceType3, units: bs.serviceQty3 });

    let includedServices = "";
    let bundledName;
    let categoryId;
    let categoryName;
    let bundledMinutes;
    if (tempArr.length > 1)
      tempArr.sort((s1, s2) => {
        const t1 = new Date(s1.createdAt).getTime();
        const t2 = new Date(s2.createdAt).getTime();
        if (t1 < t2) return -1;
        if (t1 > t2) return 1;
        return 0;
      });

    if (bs.numServices === 1)
      includedServices = `${tempArr[0].id}#${tempArr[0].units}`;
    if (bs.numServices === 2)
      includedServices = `${tempArr[0].id}#${tempArr[0].units}|${tempArr[1].id}#${tempArr[1].units}`;
    if (bs.numServices === 3)
      includedServices = `${tempArr[0].id}#${tempArr[0].units}|${tempArr[1].id}#${tempArr[1].units}|${tempArr[2].id}#${tempArr[2].units}`;

    categoryId = tempArr[0].category
      ? tempArr[0].category.id
      : tempArr[0].categoryId;
    categoryName = tempArr[0].category
      ? tempArr[0].category.name
      : tempArr[0].categoryName;
    if (tempArr.length === 1) {
      bundledName = `${tempArr[0].units} x ${tempArr[0].name}`;

      bundledMinutes = tempArr[0].minutes * tempArr[0].units;
    }
    if (tempArr.length === 2) {
      bundledName = `${tempArr[0].units} x ${tempArr[0].name} and ${tempArr[1].units} x ${tempArr[1].name}`;

      bundledMinutes =
        tempArr[0].minutes * tempArr[0].units +
        tempArr[1].minutes * tempArr[1].units;
    }
    if (tempArr.length === 3) {
      bundledName = `${tempArr[0].units} x ${tempArr[0].name}, ${tempArr[1].units} x ${tempArr[1].name} and  ${tempArr[2].units} x ${tempArr[2].name}`;
      bundledMinutes =
        tempArr[0].minutes * tempArr[0].units +
        tempArr[1].minutes * tempArr[1].units +
        tempArr[2].minutes * tempArr[2].units;
    }

    //now we have bundled services - included services id
    //first check if the bundled service exists
    const result = await API.graphql(
      graphqlOperation(bundledServicesByCompany, {
        companyId: bs.company.id,
        includedServices: { eq: includedServices },
      })
    );
    if (
      result &&
      result.data.bundledServicesByCompany.items.length &&
      result.data.bundledServicesByCompany.items[0]
    ) {
      return {
        ...result.data.bundledServicesByCompany.items[0],
        units: 1,
        componentServices: tempArr,
      }; //units=1 for consistency only for bundledservice
    }
    if (result && !result.data.bundledServicesByCompany.items.length) {
      //if bundled service does not exist, create it
      let input = {
        name: bundledName,
        minutes: bundledMinutes,
        price: bs.regionalServicePrice,
        desc: "Bundled service",
        active: true,
        serviceTypeCompanyId: bs.company.id,
        companyId: bs.company.id,
        serviceTypeCategoryId: categoryId,
        categoryId: categoryId,
        categoryName: categoryName,
        deleted: false,
        includedServices: includedServices,
        isVisible: false,
        isBundledService: true,
      };

      const newBundledService = await API.graphql(
        graphqlOperation(createServiceType, { input })
      );
      if (newBundledService && newBundledService.data.createServiceType)
        return {
          ...newBundledService.data.createServiceType,
          units: 1,
          componentServices: tempArr,
        };
      else return null;
    }
  }
  return bs.serviceType1; //serviceType1 is the main service when multipleServices is off.
}

function heldSlotsTimeoutExpired(bookingState) {
  let heldSlotTime = bookingState.heldSlotsOnDateTime;
  //900000 = 15 mins
  if (Date.now() - heldSlotTime.getTime() > 900000) return true;
  return false;
}

async function createNewUiSession(newSessionDetails) {
  return await createUISessionEntry(newSessionDetails);
}

async function createUISessionEntry({ eventName, companyId, uiSessionId }) {
  try {
    let input = {
      sessionItem: eventName,
      companyId: companyId,
    };

    if (uiSessionId) input = { ...input, id: uiSessionId };

    const newUiSessionEntry = await API.graphql(
      graphqlOperation(createUISession, { input })
    );
    if (newUiSessionEntry && newUiSessionEntry.data.createUISession)
      return newUiSessionEntry.data.createUISession.id;
    else return null;
  } catch (e) {
    console.log("error while creating UI session", eventName);
  }
}

async function updateClientPackageIfValid(bookingState) {
  if (bookingState.packageBookedSlots?.length) {
    try {
      const num_of_credits_being_booked =
        bookingState.packageBookedSlots.length;
      //Do strong read query from here but for now read using appsync
      const result = await API.graphql(
        graphqlOperation(getClientPackage, {
          id: bookingState.clientpackage.id,
        })
      );
      if (result && result.data && result.data.getClientPackage) {
        if (
          result.data.getClientPackage.initialQuantity -
            result.data.getClientPackage.usedQuantity >=
          num_of_credits_being_booked
        ) {
          await updateUsedQuantityOfPackage({
            pkg: bookingState.clientpackage,
            increaseby: num_of_credits_being_booked,
          });
          return true;
        } else return false; //not enough credits
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else return false;
}

function getResponseRate(provider) {
  if (provider.AcceptanceRatios) {
    try {
      if (typeof provider.AcceptanceRatios === "string")
        provider.AcceptanceRatios = JSON.parse(provider.AcceptanceRatios);
    } catch (e) {
      return 0;
    }
    if (
      provider.AcceptanceRatios.recent &&
      provider.AcceptanceRatios.recent.avg
    ) {
      return provider.AcceptanceRatios.recent.avg.pri_rep_acceptance_ratio ===
        -1
        ? 0
        : provider.AcceptanceRatios.recent.avg.pri_rep_acceptance_ratio;
    } else if (provider.AcceptanceRatios.avg) {
      return provider.AcceptanceRatios.avg.pri_rep_acceptance_ratio === -1
        ? 0
        : provider.AcceptanceRatios.avg.pri_rep_acceptance_ratio;
    } else return 0;
  } else return 0;
}

function getResponseTime(provider) {
  if (provider.AcceptanceRatios) {
    try {
      if (typeof provider.AcceptanceRatios === "string")
        provider.AcceptanceRatios = JSON.parse(provider.AcceptanceRatios);
    } catch (e) {
      return 0;
    }
    let avg = provider.AcceptanceRatios.recent
      ? provider.AcceptanceRatios.recent.avg
      : provider.AcceptanceRatios.avg;
    if (!avg) return "";
    let rep_mins = avg.pri_rep_mins;
    if (rep_mins !== -1) {
      if (rep_mins <= 30) return "a few minutes";
      if (rep_mins > 30 && rep_mins <= 60) return "within an hour";
      let hours = Math.floor(rep_mins / 60);
      if (hours < 3) return "a couple of hours";
      if (hours >= 3) return `${hours} hours`;
      if (hours > 24) {
        let days = Math.floor(hours / 24);
        return `${days} days`;
      }
    }
  }
  return "";
}

async function sendGuestCheckoutEmail({
  bookingUser, //bookingUser is the guest
  company,
}) {
  try {
    const guestCheckoutEmailBody = await guestCheckoutEmail({
      client_firstname: bookingUser.firstname,
      client_lastname: bookingUser.lastname,
      client_email: bookingUser.emailaddress,
      companyId: company.id,
      company,
    });
    const body = `
      ${guestCheckoutEmailBody}
    `;
    await handleSendEmail(
      `Access your appointment and order details`,
      body,
      [bookingUser.emailaddress],
      [],
      [],
      company.replyemailaddress,
      company.name
    );
    return guestCheckoutEmailBody;
  } catch (err) {
    console.error("error while sending sendGuestCheckoutEmail");
    console.error(err);
  }
}

export {
  getPaidPackages,
  updateUsedQuantityOfPackage,
  updateClientPackageToPaid,
  updateOrderStatusToPaidByPkgCredit,
  updateOrderStatusToPaid,
  updateOrderStatusToClosed,
  cancelBooking,
  saveBillingProgress,
  calculateChargeBreakup,
  updateClientBalanceTo,
  holdSlotsForAppointments,
  getClientIfExists,
  createClientRecord,
  updateHeldSlotsToConfirmed,
  deleteHeldSlots,
  sendOrderReceipt,
  handleOrderAndBookingCreation,
  saveClientPackage,
  fetchPackages,
  fetchRegionalPricing,
  fetchCompanyLocation,
  getPrice,
  getDescription,
  getStripeAmount,
  saveBookingRequest,
  getCurrency,
  getCurrencySymbol,
  sendProviderBookingConfirmation,
  getOrderTrackingData,
  totalRegionalPrice,
  findOrCreateService,
  heldSlotsTimeoutExpired,
  createNewUiSession,
  updateClientPackageIfValid,
  getResponseRate,
  getResponseTime,
  getTaxJurisdiction,
  createOsd,
  updateOrderStatusToConfirmed,
  holdSlotForPackageAppointments,
  sendGuestCheckoutEmail,
  formatterCurr,
};
