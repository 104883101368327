export const dayAbbreviations = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const VARIABLE_TAX_RATE = "variable";

export const MAX_FIRST_NAME_LENGTH = 15;
export const LOCATION_PREFIXES = ["PL", "CL", "GL"];
export const LOCATION_PREFIXES_REGEX = new RegExp(
  LOCATION_PREFIXES.join("-|^")
);
export const AGNOSTIC_PROVIDER_NAME_TRUNCATE_LENGTH = 9;
